import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Document {
    id: number;
    name: string;
    id_user: number;
    checked: boolean;
    deleted: boolean;
}

interface Company {
    id: number;
    name: string;
    documents: Document[];
}

interface Employee {
    id: number;
    nome: string;
    cognome: string;
    documents: Document[];
}

interface Workingtool {
  id: number;
  name: string;
  documents: Document[];
}


@Component({
    selector: 'documents-as-checkboxes',
    template: require('./documents-as-checkboxes.pug'),
    styles: [require('./documents-as-checkboxes.css')],
})

export class DocumentsAsCheckboxesComponent implements OnInit, OnDestroy {

    @Input()  company: Company;
    @Input()  employees: Employee[];
    @Input()  workingtools: Workingtool[];
    @Input()  selectedDocuments: Document[];

    @Output() selectedChanges = new EventEmitter<Document[]>();

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;

    selectAll = false;

    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route;
        this.authService = authService;

        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    ngOnInit() {
    }

    changeCheckbox(document) {
        document.checked = !document.checked;
        var list = []
        this.company.documents.forEach(d => {
          if(d.checked){
            list.push(d);
          }
        });
        this.employees.forEach(e => {
          if(e.documents){
            e.documents.forEach(d => {
              if(d.checked){
                list.push(d);
              }
            });
          }
        });
        this.workingtools.forEach(wt => {
          if(wt.documents){
            wt.documents.forEach(d => {
              if(d.checked){
                list.push(d);
              }
            });
          }
        });
        this.selectedDocuments = list;
        this.selectedChanges.emit(this.selectedDocuments);
    }

    selectAllDocuments() {
      this.selectAll = !this.selectAll;
      var list = []
      if(this.company.documents){
        this.company.documents.forEach(d => {
          d.checked = this.selectAll;
          if(d.checked && !d.deleted) {
            list.push(d);
          }
        });
      }
      this.employees.forEach(e => {
        if(e.documents){
          e.documents.forEach(d => {
            d.checked = this.selectAll;
            if(d.checked && !d.deleted) {
              list.push(d);
            }
          });            
        }
      });
      this.workingtools.forEach(wt => {
        if(wt.documents){
          wt.documents.forEach(d => {
            d.checked = this.selectAll;
            if(d.checked && !d.deleted) {
              list.push(d);
            }
          });
        }
      });
      this.selectedDocuments = list;
      this.selectedChanges.emit(this.selectedDocuments);
  }

    ngOnChanges(changes: SimpleChanges) {
      this.company.documents.forEach(d => {
        d.checked = this.inArrayById(this.selectedDocuments, d);
      });

      this.employees.forEach(e => {
        e.documents.forEach(d => {
            d.checked = this.inArrayById(this.selectedDocuments, d);
        });
      });

      this.workingtools.forEach(wt => {
        wt.documents.forEach(d => {
          d.checked = this.inArrayById(this.selectedDocuments, d);
        });
      });
    }

    inArrayById(array, item) {
      for(var i=0; i < array.length; i++) {
        if(array[i].id === item.id){
          return true;
        }
      }
      return false;
    };



    ngOnDestroy() {
    }


    notDeleted(list){
      return list.filter(x => !x.deleted);
    }
}
