import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Countermeasure {
    id: number;
    name: string;
    description: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    Dpis: Dpi[];
    Risks: Risk[];
}

interface Dpi {
    id: number;
    name: string;
}

interface Risk {
    id: number;
    name: string;
}


@Component({
    selector: 'countermeasure-component',
    template: require('./countermeasure.pug'),
    styles: [require('./countermeasure.css')],
})

export class CountermeasureComponent implements OnInit, OnDestroy {

    @Input() input: Countermeasure; 

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    countermeasure: Countermeasure;
    newCountermeasure='';
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }
    

    ngOnInit() {    
    }
    
    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.input;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.route.queryParams.subscribe(params => {
          this.http.get('/api/countermeasures/'+ change.currentValue.id).subscribe((countermeasureLoaded) => {
              this.countermeasure = countermeasureLoaded as Countermeasure;
              console.log("================================");
              console.log(this.countermeasure );
          });
      });    
      
    }
    

    ngOnDestroy() {
    }

    updateCountermeasure(form) {
      if(form.invalid) return;
      this.submitted = true;
      return this.http.put('/api/countermeasures/'+this.countermeasure.id, this.countermeasure)
          .subscribe(countermeasureUpdated => {
              console.log('Updated Countermeasure:', countermeasureUpdated);
          });
    }

    deleteCountermeasure(countermeasure) {
        return this.http.delete(`/api/countermeasures/${countermeasure._id}`)
            .subscribe(() => {
                console.log('Deleted Countermeasure');
            });
    }
    
    onDpisSelectedChanges( dpisSelected: Dpi[]) {
        this.countermeasure.Dpis = dpisSelected;
    }

    onRisksSelectedChanges( risksSelected: Risk[]) {
        this.countermeasure.Risks = risksSelected;
    }

}
