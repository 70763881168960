import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { DocumentsComponent } from './documents.component';
import { SocketService } from '../../components/socket/socket.service';
import { DirectivesModule } from '../../components/directives.module';


export const ROUTES: Routes = [
    { path: 'documents', component: DocumentsComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        DirectivesModule,
    ],
    declarations: [
        DocumentsComponent,
    ],
    providers: [
        SocketService,
    ],
    exports: [
        DocumentsComponent,
    ],
})
export class DocumentsModule {}
