import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Risk {
    id: number;
    name: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    selected: boolean;
}

@Component({
    selector: 'risks-as-checkboxes',
    template: require('./risks-as-checkboxes.pug'),
    styles: [require('./risks-as-checkboxes.css')],
})

export class RisksAsCheckboxesComponent implements OnInit, OnDestroy {

    @Input() input: Risk[]; 
    @Output() selectedChanges = new EventEmitter<Risk[]>();

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    selectedRisks: Risk[];
    risks: Risk[];
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    ngOnInit() {    
    }

    changeCheckbox(risk) {
        risk.selected = !risk.selected;
        this.selectedRisks = this.risks.filter( d => {
              return d.selected;
        });
        this.selectedChanges.emit(this.selectedRisks);
    }
    
    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.input;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.selectedRisks = change.currentValue

      this.route.queryParams.subscribe(params => {
          this.http.get('/api/risks/').subscribe( (risksLoaded: Risk[]) => {
              this.risks = risksLoaded;              
              this.selectedRisks.forEach( ds => {
                this.risks.forEach( d => {
                  if(d.id == ds.id){
                    d.selected = true;
                  }
                  
                });
              });

          });
      });    
    }

    ngOnDestroy() {
    }

}
