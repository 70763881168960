import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../components/auth/auth.service';
import SecuritytaskEvents from 'server/api/securitytask/securitytask.events';


interface Company {
    id: number;
    id_user: number;
    name: string;
    Employees: Employee[];
    subcompany: boolean;
    deleted: boolean;
}

interface Securitytask {
    id: number;
    name: string;
    pos_mandatory: boolean;
    pimus_mandatory: boolean;
}

interface Employee {
    id: number;
    nome: string;
    datore_lavoro: boolean;
    Securitytasks: Securitytask[];
}

@Component({
    selector: 'welcome',
    template: require('./welcome.pug'),
    styles: [require('./welcome.css')],
})
export class WelcomeComponent implements OnInit {

    company: Company;
    companies: Company[];
    securitytasksNotCoveredPos: Securitytask[] = [];
    securitytasksNotCoveredPimus: Securitytask[] = [];
    datoreDiLavoroIsPresent = false;
    expiringDays = '';
    expiringDate = '';
    isAdmin = false;
    isLoggedIn = false;
    currentUser = {};
    securitytasks = null;

    static parameters = [HttpClient, Router, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private authService: AuthService) {
        this.http = http;
        this.router = router;
    }

    ngOnInit() {    
        this.expiringDays = localStorage.getItem('expiringDays');
        this.expiringDate = localStorage.getItem('expiringDate');
        this.selectCompany(this.authService.getCurrentCompany())
        
        this.http.get('/api/companies').subscribe( (list: Company[]) => {
            this.companies = list.filter( o => {return !o.subcompany && !o.deleted}); // tolgo le imprese in subappalto o cancellate
            if( !this.company && (this.companies.length > 0) ){
                this.selectCompany( this.companies[0] );
            }
        });
        this.currentUser =  this.authService._currentUser;
        if(this.company && this.currentUser["_id"] != this.company.id_user){
            this.company = null;
            this.authService.setCurrentCompany(null);
        }
   }

    selectCompany(c: Company){
        if(c){
            this.http.get('/api/companies/'+c.id).subscribe( (loadedCompany: Company) => {
                this.company = loadedCompany;
                this.authService.setCurrentCompany(loadedCompany);
                this.updateSecuritytasks();
            });
        }else{
            this.authService.setCurrentCompany(null);
        }        
    }
    

    updateSecuritytasks(){
        if(!this.securitytasks){
            this.http.get('/api/securitytasks').subscribe((securitytasks: any[]) => {
                this.securitytasks = securitytasks;
                this.updateSecuritytasks();
            });
            return;
        }
        
        this.datoreDiLavoroIsPresent = false;
        var securitytaskNamesOfAllEmployees = [];

        this.company.Employees.forEach( e => {
            if(e.datore_lavoro){
                this.datoreDiLavoroIsPresent = true;
            }
            e.Securitytasks.forEach( est => {
                securitytaskNamesOfAllEmployees.push(est.name);
            })
        });

        this.securitytasksNotCoveredPos = [];
        this.securitytasksNotCoveredPimus = [];
        
        this.securitytasks.forEach( st => {
            if (st.pos_mandatory && securitytaskNamesOfAllEmployees.indexOf(st.name) < 0 ){
                this.securitytasksNotCoveredPos.push(st.name);
            }
            if (st.pimus_mandatory && securitytaskNamesOfAllEmployees.indexOf(st.name) < 0){
                this.securitytasksNotCoveredPimus.push(st.name);
            }
        })
    }



    compareById(o1, o2) {
        return o1 && o2 && o1.id == o2.id;
    }


    addCompany() {
        var r = confirm("Stai per inserire una nuova IMPRESA. Premi OK per procedere"); 
        if (r == true) {
            var obj = { name: 'NUOVA IMPRESA', id_parent_company: null, subcompany:false };
            return this.http.post('/api/companies', obj).subscribe( (companyCreated: Company) => {
                    console.log('Added Company:', companyCreated);
                    const url = '/company/'+companyCreated.id;
                    this.router.navigate([url]);
            });
        }
    }



}