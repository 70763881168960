import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Employee {
    id: number;
    type: string;
    name: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    checked: boolean;
    Securitytasks: Securitytask[];
    inSite: boolean;
    datore_lavoro: boolean;
}

interface Securitytask {
    id: number;
    name: string;
    in_site: boolean;
}

@Component({
    selector: 'employees-as-checkboxes',
    template: require('./employees-as-checkboxes.pug'),
    styles: [require('./employees-as-checkboxes.css')],
})

export class EmployeesAsCheckboxesComponent implements OnInit, OnDestroy {

    @Input()  showOnlyInSite: boolean;
    @Input()  selectedEmployees: Employee[];
    @Input()  employees: Employee[];
    @Output() selectedChanges = new EventEmitter<Employee[]>();

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;

    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route;
        this.authService = authService;

        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    ngOnInit() {
    }

    changeCheckbox(employee) {
        employee.checked = !employee.checked;
        this.selectedEmployees = this.employees.filter( d => {
            return d.checked;
        });
        this.selectedChanges.emit(this.selectedEmployees);
    }

    ngOnChanges(changes: SimpleChanges) {
        if(!this.selectedEmployees){
            this.selectedEmployees = [];
        }
        this.selectedEmployees.forEach( ds => {
            if(!this.employees){
                this.employees = [];
            }
            this.employees.forEach( d => {
                if(d.id == ds.id){
                    d.checked = true;
                }
                this.setEmployeeInSite(d);
            });
        });
    }

    setEmployeeInSite(employee: Employee){
        if(employee.type=="Dipendente"){
            employee.inSite = true;
            return;
        }
        if(employee.Securitytasks.length == 0 && employee.datore_lavoro){
            employee.inSite = false;
            return;
        }
        if(employee.Securitytasks.length == 0){
            employee.inSite = true;
            return;
        } else{
            var offSite = true;
            employee.Securitytasks.forEach( st => {
                if(st.in_site){
                    offSite = false;
                }
            })
            employee.inSite = !offSite; 
            return;
        }
    }


    ngOnDestroy() {
    }

    notDeletedAndInSite(list){
        return list.filter(x => !x.deleted && x.inSite);
      }
}
