import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Company {
    id: number;
    name: string;
    id_user: number;
    checked: boolean;
}

@Component({
    selector: 'companies-as-checkboxes',
    template: require('./companies-as-checkboxes.pug'),
    styles: [require('./companies-as-checkboxes.css')],
})

export class CompaniesAsCheckboxesComponent {

    @Input() selectedCompanies: Company[]; 
    @Input() allCompanies: Company[]; 
    @Output() selectedChanges = new EventEmitter<Company[]>();

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });
        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    changeCheckbox(company) {
        company.checked = !company.checked;
        this.selectedCompanies = this.allCompanies.filter( o => {return o.checked;} );
        this.selectedChanges.emit(this.selectedCompanies);
    }    

    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.selectedCompanies;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.selectedCompanies = change.currentValue

      this.selectedCompanies.forEach( c => {
          this.allCompanies.forEach( d => {
            if(d.id == c.id){
              d.checked=true;
            }
          });
      });

    }
}
