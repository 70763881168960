import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Dpi {
    id: number;
    name: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    checked: boolean;
}

@Component({
    selector: 'dpis-as-checkboxes',
    template: require('./dpis-as-checkboxes.pug'),
    styles: [require('./dpis-as-checkboxes.css')],
})

export class DpisAsCheckboxesComponent implements OnInit, OnDestroy {

    @Input() input: Dpi[]; 
    @Output() selectedChanges = new EventEmitter<Dpi[]>();

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    selectedDpis: Dpi[];
    dpis: Dpi[];
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    ngOnInit() {    
    }

    changeCheckbox(dpi) {
        dpi.checked = !dpi.checked;
        if(dpi.checked){
          this.selectedDpis.push(dpi)
        }else{
          this.selectedDpis = this.dpis.filter( d => {
              return d.checked;
          });
        }
        this.selectedChanges.emit(this.selectedDpis);
    }
    
    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.input;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.selectedDpis = change.currentValue

      this.route.queryParams.subscribe(params => {
          this.http.get('/api/dpis/').subscribe( (dpisLoaded: Dpi[]) => {
              this.dpis = dpisLoaded;              
              this.selectedDpis.forEach( ds => {
                this.dpis.forEach( d => {
                  if(d.id == ds.id){
                    d.checked=true;
                  }
                });
              });

          });
      });    
    }

    ngOnDestroy() {
    }

}
