import {
    NgModule,
    ApplicationRef,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {
    removeNgStyles,
    createNewHosts,
    createInputTransfer,
} from '@angularclass/hmr';

import { RouterModule, Routes } from '@angular/router';

import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { ShortStringPipe } from './app.shortString.pipe';

import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { WelcomeModule } from './welcome/welcome.module';
import { DirectivesModule } from '../components/directives.module';
import { JwtModule } from '@auth0/angular-jwt';
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';
//import { RisksModule } from './risks/risks.module';
import { RisksModule } from './administrator/risks/risks.module';
import { RiskModule } from './administrator/risk/risk.module';
import { DpisModule } from './dpis/dpis.module';
import { EmployeetypesModule } from './employeetypes/employeetypes.module';
import { WorkingtooltypesModule } from './workingtooltypes/workingtooltypes.module';
import { WorkingtoolsModule } from './workingtools/workingtools.module';
import { WorkingtoolModule } from './workingtool/workingtool.module';
import { SectorsModule } from './sectors/sectors.module';
import { PhasetypesModule } from './phasetypes/phasetypes.module';
import { PhasesModule } from './administrator/phases/phases.module';
import { PhaseModule } from './administrator/phase/phase.module';
import { SubphasesModule } from './subphases/subphases.module';
import { CountermeasuresPageModule } from './countermeasures/countermeasures.module';
//import { AdminphasesModule } from './adminphases/adminphases.module';
import { PosListModule } from './posList/posList.module';
import { PimusListModule } from './pimusList/pimusList.module';
import { PosPageModule } from './pos/pos.module';
import { PimusPageModule } from './pimus/pimus.module';
import { CompanyModule } from './company/company.module';
import { CompaniesModule } from './companies/companies.module';
import { EmployeesModule } from './employees/employees.module';
import { EmployeeModule } from './employee/employee.module';
import { DocumentsModule } from './documents/documents.module';
import { DocumentModule } from './document/document.module';
import { PaymentModule } from './payment/payment.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//TOADD

import { FileUploadModule } from 'ng2-file-upload';
import { MyDatePickerModule } from 'mydatepicker';

import { AgmCoreModule } from '@agm/core';


export function tokenGetter() {
    return localStorage.getItem('id_token');
}

const appRoutes: Routes = [{ path: '',
    redirectTo: '/home',
    pathMatch: 'full'
}];

@NgModule({
    imports: [
        AgmCoreModule.forRoot({
          apiKey: "AIzaSyC8WEzE_-9z_pkymgUl4JEgIHvEXQvnpcY",
          libraries: ["places"]
        }),

        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,

        JwtModule.forRoot({
            config: {
                tokenGetter,
            }
        }),

        RouterModule.forRoot(appRoutes, { enableTracing: process.env.NODE_ENV === 'development' }),

        VirtualScrollerModule,

        MainModule,
        WelcomeModule,
        DirectivesModule,
        AccountModule,
        AdminModule,
        RisksModule,
        RiskModule,
        DpisModule,
        EmployeetypesModule,
        WorkingtooltypesModule,
        CountermeasuresPageModule,
        SectorsModule,
        PhasetypesModule,
        PhasesModule,
        PhaseModule,
        SubphasesModule,
        //AdminphasesModule,
        PosListModule,
        PimusListModule,
        PosPageModule,
        PimusPageModule,
        CompaniesModule,
        CompanyModule,
        EmployeesModule,
        EmployeeModule,
        DocumentsModule,
        DocumentModule,
        PaymentModule,
        WorkingtoolsModule,
        WorkingtoolModule,
        FileUploadModule,
        //TOADD
        MyDatePickerModule
    ],
    declarations: [
        AppComponent,
        ShortStringPipe, 
    ],
    bootstrap: [AppComponent],

})
export class AppModule {
    static parameters = [ApplicationRef];
    constructor(private appRef: ApplicationRef) {
        this.appRef = appRef;
    }

    hmrOnInit(store) {
        if (!store || !store.state) return;
        console.log('HMR store', store);
        console.log('store.state.data:', store.state.data);
        // inject AppStore here and update it
        // this.AppStore.update(store.state)
        if ('restoreInputValues' in store) {
            store.restoreInputValues();
        }
        // change detection
        this.appRef.tick();
        Reflect.deleteProperty(store, 'state');
        Reflect.deleteProperty(store, 'restoreInputValues');
    }

    hmrOnDestroy(store) {
        var cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // inject your AppStore and grab state then set it on store
        // var appState = this.AppStore.get()
        store.state = {data: 'yolo'};
        // store.state = Object.assign({}, appState)
        // save input values
        store.restoreInputValues = createInputTransfer();
        // remove styles
        removeNgStyles();
    }

    hmrAfterDestroy(store) {
        // display new elements
        store.disposeOldHosts();
        Reflect.deleteProperty(store, 'disposeOldHosts');
        // anything you need done the component is removed
    }
}
