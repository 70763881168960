import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface Employeetype {
    name: string;
    public: boolean;
    info?: string;
}


@Component({
    selector: 'employeetypes',
    template: require('./employeetypes.pug'),
    styles: [require('./employeetypes.css')],
})

export class EmployeetypesComponent implements OnInit, OnDestroy {
    employeetypes: Employeetype[] = [];
    
    selectedEmployeetype: Employeetype;
    newEmployeetype = '';

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    ngOnInit() {    
        this.http.get('/api/employeetypes')
            .subscribe((employeetypes: Employeetype[]) => {
                this.employeetypes = employeetypes;
            });
    }

    ngOnDestroy() {
    }

    addEmployeetype() {
        if(this.newEmployeetype) {
            let text = this.newEmployeetype;
            this.newEmployeetype = '';

            return this.http.post('/api/employeetypes', { name: text })
                .subscribe(employeetype => {
                    console.log('Added Employeetype:', employeetype);
                    
                    this.employeetypes.push(employeetype as Employeetype);
                    this.selectedEmployeetype = employeetype as Employeetype;
                });
        }
    }

    deleteEmployeetype(employeetype) {
        return this.http.delete(`/api/employeetypes/${employeetype._id}`)
            .subscribe(() => {
                console.log('Deleted Employeetype');
            });
    }
}
