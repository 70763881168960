import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Sector {
    id: number;
    name: string;
}


interface Workingtooltype {
  id: number;
  name: string;
}

interface Employeetype {
  id: number;
  name: string;
}

interface Company {
    id: number;
    name: string;
    description: string;
    addressSedeLegale: string;
    addressSedeOperativa: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    Sector: Sector;
    Workingtooltypes: Workingtooltype[];
    Employeetypes: Employeetype[];
}


@Component({
    selector: 'company-component',
    template: require('./company.pug'),
    styles: [require('./company.css')],
})
export class CompanyComponent implements OnInit, OnDestroy {

    @Input() 
    company: Company;

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;

    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route;
        this.authService = authService;

        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }


    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.company;
      var companyInInput = change.currentValue as Company;
      return this.http.get('/api/companies/'+companyInInput.id).subscribe( (companyLoaded: Company) => {
              this.company = companyLoaded;
          });
    }

    ngOnDestroy() {
    }

    saveCompany(form) {
      if(form.invalid) return;
      this.submitted = true;
      return this.updateCompany;
    }

    updateCompany() {
      return this.http.put('/api/companies/'+this.company.id, this.company)
          .subscribe(companyUpdated => {
              console.log('Updated Company:', companyUpdated);
          });
    }


    deleteCompany(company) {
        return this.http.delete(`/api/companies/${company._id}`)
            .subscribe(() => {
                console.log('Deleted Company');
            });
    }

    onSectorChange( sectorSelected: Sector) {
        this.company.Sector = sectorSelected;
    }

    onAddressSedeLegaleChange( address: string) {
        this.company.addressSedeLegale = address;
    }

    onAddressSedeOperativaChange( address: string) {
        this.company.addressSedeOperativa = address;
    }


    onWorkingtooltypesSelectedChanges( wtsSelected: Workingtooltype[]) {
        this.company.Workingtooltypes = wtsSelected;
        this.updateCompany();
    }

    onEmployeetypesSelectedChanges( etsSelected: Employeetype[]) {
        this.company.Employeetypes = etsSelected;
        this.updateCompany();
    }


}
