import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap';

import { WorkingtoolComponent } from './workingtool.component';
import { SocketService } from '../../components/socket/socket.service';

import { FileUploadModule } from 'ng2-file-upload';

export const ROUTES: Routes = [
    { path: 'workingtool/:id', component: WorkingtoolComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        FileUploadModule, 
    ],
    declarations: [
        WorkingtoolComponent,
    ],
    providers: [
      SocketService,
    ],
    exports: [
        WorkingtoolComponent,
    ],
})
export class WorkingtoolModule {}
