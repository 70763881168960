import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Subphase {
    id: number;
    name: string;
    description: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    Risks: Risk[];
}

interface Risk {
    id: number;
    name: string;
}


@Component({
    selector: 'subphase-component',
    template: require('./subphase.pug'),
    styles: [require('./subphase.css')],
})

export class SubphaseComponent implements OnInit, OnDestroy {

    @Input() input: Subphase; 

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    subphase: Subphase;
    newCountermeasure='';
    risks: Risk[];
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }
    

    ngOnInit() {    
    }
    
    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.input;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.subphase = change.currentValue as Subphase;
    }
    
    ngOnDestroy() {
    }

    updateSubphase(form) {
      if(form.invalid) return;
      this.submitted = true;
      return this.http.put('/api/subphases/'+this.subphase.id, this.subphase)
          .subscribe(subphaseUpdated => {
              console.log('Updated Subphase:', subphaseUpdated);
          });
    }

    deleteSubphase(subphase) {
        return this.http.delete(`/api/subphases/${subphase._id}`)
            .subscribe(() => {
                console.log('Deleted Subphase');
            });
    }
        
    onRisksSelectedChanges( risksSelected: Risk[]) {
        this.subphase.Risks = risksSelected;
    }

}
