import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange,  Output, EventEmitter,} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Phase {
    id: number;
    name: string;
    info?: string;
    Subphases: Subphase[];
    filteredSubphases: Subphase[];
}

interface Subphase {
    phase: Phase;
    id: number;
    name: string;
    public: boolean;
    info?: string;
    selected: boolean;
}


@Component({
    selector: 'subphases-as-checkboxes',
    template: require('./subphases-as-checkboxes.pug'),
    styles: [require('./subphases-as-checkboxes.css')],
})
export class SubphasesAsCheckboxesComponent implements OnInit {

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    @Output() addedSubphase = new EventEmitter<Subphase>();
    @Output() removedSubphase = new EventEmitter<Subphase>();

    phases: Phase[];
    filter: string = "";
    currentUserId = "";
    selectedSubphases: Subphase[];
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
        
        this.authService.getCurrentUser().then( u => {
            this.currentUserId = u._id;
        })
        this.selectedSubphases = [];

    }
    
    ngOnInit() {
        this.reload();
    }
    
    reload() {
        console.log("SubphasesAsCheckboxesComponent.ngOnInit");
        this.http.get('/api/phases').subscribe( (list: Phase[]) => {
                this.phases = list;
                this.assignCopy();
        });
    }
    
    assignCopy(){
       this.phases.forEach( phase => {
           phase.filteredSubphases = Object.assign([], phase.Subphases);      
       })
    }
    
    filterSubphases(){
       this.assignCopy();
       if(this.filter){
           this.phases.forEach( phase => {
                phase.filteredSubphases = phase.Subphases.filter(
                    item => item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
                )
           })
       }
    }

    changeSubphase(subphase) {
        subphase.selected = !subphase.selected;
        var added: Subphase;
        var removed: Subphase;
        
        if(subphase.selected){
          this.selectedSubphases.push(subphase);
          added = subphase;
        }else{
            this.phases.forEach( phase => {
                this.selectedSubphases = phase.filteredSubphases.filter( d => {
                    return d.selected;
                });
            });
            removed = subphase;
        }
        console.log("subphase ADDEDD", added);
        this.addedSubphase.emit( added );
    }
    
}