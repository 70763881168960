import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';

interface Risk {
  id: number;
  id_parent: number;
  name: string;
  description: string;
  isEditing: boolean;
  Countermeasures: any[];
  public: boolean;
  checked: boolean;
  deleted: boolean;
  draft: boolean;
}

interface Countermeasure {
  id: number;
  name: string;
  public: boolean;
  description: string;
  isEditing: boolean;
  Dpis: any[];
}

@Component({
    selector: 'risk',
    template: require('./risk.pug'),
    styles: [require('./risk.css')],
})

export class RiskComponent implements OnInit, OnDestroy {

  risk: Risk;
  
  static parameters = [HttpClient, Router,  ActivatedRoute];
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
      this.http = http;
      this.route = route;
  }

  ngOnInit() {
      this.route.paramMap.subscribe( params => {
          var id: string = params.get('id');
          this.http.get('/api/risks/'+id).subscribe((risk: Risk) => {
              this.risk = risk;
          });
      });
  }

  ngOnDestroy() {
  }


  inArray(array, comparer) {
    for(var i=0; i < array.length; i++) {
      if(comparer(array[i])){
        return true;
      }
    }
    return false;
  };

  inArrayById(array, item) {
    for(var i=0; i < array.length; i++) {
      if(array[i].id === item.id){
        return true;
      }
    }
    return false;
  };

  doTextareaValueChange(ev, obj) {
    try {
      //console.info(obj);
      obj = ev.target.value;
      //console.info(obj);
      this.updateRisk();
    } catch(e) {
      console.info('could not set textarea-value');
    }
  }

  deleteCountermeasure(countermeasure: any){
    this.http.delete('/api/countermeasures/'+countermeasure.id, countermeasure).subscribe( (countermeasureDeleted) => {
         console.log('Deleted countermeasure:', countermeasureDeleted);
         this.risk.Countermeasures = this.arrayRemove(this.risk.Countermeasures, countermeasure);
         this.updateRisk();          
    });  
  }

  addCountermeasure(risk: Risk){
    const countermeasure = {name: 'Nuova contromisura per il rischio'+risk.name+'.', description: 'Inserisci qui la descrizione della nuova contromisura.', Dpis: [], id_risk: risk.id, isEditing: true, public: true};
    this.http.post('/api/countermeasures/', countermeasure).subscribe( (countermeasureCreated: any)  => {
        console.log('Created countermeasure:', countermeasureCreated);
        countermeasureCreated.isEditing = false;
        countermeasureCreated.Dpis = [];
        risk.Countermeasures.push(countermeasureCreated);
        this.updateRisk();
    });  
  }
  
  
  editCountermeasure(risk: Risk, countermeasure: Countermeasure){
      if(countermeasure.isEditing){
          countermeasure.public = true;
          this.http.put('/api/countermeasures/'+countermeasure.id, countermeasure).subscribe( (countermeasureUpdated: any) => {
             console.log('Updated Countermeasure:', countermeasureUpdated);
             countermeasure.isEditing = false;
          });  
      }else{
        countermeasure.isEditing = true;
      }
  }
  
  arrayRemove(array, o) {
       return array.filter(e => {
           return e.id != o.id 
       });
  }
 

  onAddedCountermeasure( risk, countermeasure: any) {
    this.http.post('/api/countermeasures/', {id_parent: countermeasure.id}).subscribe( (countermeasureCreated: any)  => {
        console.log('Created countermeasure:', countermeasureCreated);
        countermeasureCreated.isEditing = false;
        risk.Countermeasures.push(countermeasureCreated);
        this.updateRisk();
    });  
  }

  editRisk(risk: Risk){
      if(risk.isEditing){
          this.http.put('/api/risks/'+risk.id, risk).subscribe( () => {
              console.log('Updated risk');
              risk.isEditing = false;
          });  
      }else{
          risk.isEditing = true;
      }
  }
    
  updateRisk(){
          this.http.put('/api/risks/'+this.risk.id, this.risk).subscribe( () => {
              console.log('Updated risk');
          });  
  }
    
    
  array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  };
  
  completed(){
    this.risk.draft = false;
    const url = '/adminrisks';
    this.router.navigate([url]);
  }

  delete(){
    this.risk.deleted = !this.risk.deleted;
    this.updateRisk()
  }

  public(){
    this.risk.public = !this.risk.public;
    this.updateRisk()
  }

  check(){
    this.risk.checked = !this.risk.checked;
    this.updateRisk()
  }

  draft(){
    this.risk.draft = !this.risk.draft;
    this.risk.checked = false;
    this.risk.public = false;
    this.updateRisk()
  }


}