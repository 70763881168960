import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface Workingtooltype {
    name: string;
    public: boolean;
    info?: string;
}


@Component({
    selector: 'workingtooltypes',
    template: require('./workingtooltypes.pug'),
    styles: [require('./workingtooltypes.css')],
})

export class WorkingtooltypesComponent implements OnInit, OnDestroy {
    workingtooltypes: Workingtooltype[] = [];
    
    selectedWorkingtooltype: Workingtooltype;
    newWorkingtooltype = '';

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    ngOnInit() {    
        this.http.get('/api/workingtooltypes')
            .subscribe((workingtooltypes: Workingtooltype[]) => {
                this.workingtooltypes = workingtooltypes;
            });
    }

    ngOnDestroy() {
    }

    addWorkingtooltype() {
        if(this.newWorkingtooltype) {
            let text = this.newWorkingtooltype;
            this.newWorkingtooltype = '';

            return this.http.post('/api/workingtooltypes', { name: text })
                .subscribe(workingtooltype => {
                    console.log('Added Workingtooltype:', workingtooltype);
                    
                    this.workingtooltypes.push(workingtooltype as Workingtooltype);
                    this.selectedWorkingtooltype = workingtooltype as Workingtooltype;
                });
        }
    }

    deleteWorkingtooltype(workingtooltype) {
        return this.http.delete(`/api/workingtooltypes/${workingtooltype._id}`)
            .subscribe(() => {
                console.log('Deleted Workingtooltype');
            });
    }
}
