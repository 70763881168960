import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import {IMyDpOptions, IMyDateModel} from 'mydatepicker';

interface Workingtooltype {
  id: number;
  name: string;
  workingtools: Workingtool[];
}

interface Workingtool {
  id: number;
  brand: string;
  model: string;
  documents: any[];
}

interface Employeetype {
  id: number;
  name: string;
}


interface Pimus {
    id: number;
    info?: string;
    id_company: number;
    fulladdress: string;
    address1: string;
    data_documento: Date;
    data_documento_js: any;
    operanti : string;
    subappalti : string;
    documenti : string;
    contesto : string;
    fasi_lavorative :  string;
    macchine : string;
    figure : string;
    array_qualifiche : string;
    desc_sintetica : string;
    dettagli : string;
    responsabile_lavori : string;
    committente : string;
    appaltatrice : string;
    coordinatore_sicurezza : string;
    direttore_lavori : string;
    prot : string;
    rev : string;
    deleted: boolean;
    workingtools: Workingtool[];
    Workingtooltypes: Workingtooltype[];
    Employeetypes: Employeetype[];
    Company: any;
    Subcompanies: any[];
    Employees: any[];
    documents: any[];
    errors: string[];
    valid: boolean;
    automate: boolean;
}

interface EmployeeInSecuritytask {
    id: number;
    nome: string;
    cognome: string;
    securitytaskname: string;
    securitytaskid: number;
    checked: boolean;
}

interface Company {
  id: number;
  name: string;
  Workingtooltypes: Workingtooltype[];
  documents: any[];
}

interface Employee {
    id: number;
    type: string;
    nome: string;
    cognome: string;
    in_site: boolean;
    datore_lavoro: boolean;
    Securitytasks: Securitytask[];
    documents: any[];
}

interface Securitytask {
    id: number;
    name: string;
    nominatedEmployees: EmployeeInSecuritytask[];
    in_site: boolean;
}


@Component({
    selector: 'pimus',
    template: require('./pimus.pug'),
    styles: [require('./pimus.css')],
})

export class PimusPageComponent implements OnInit, OnDestroy {

    public dateOptions: IMyDpOptions = {
      dateFormat: 'dd-mm-yyyy',
      dayLabels:	{su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Gio', fr: 'Ven', sa: 'Sab'},
      monthLabels:	{ 1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug', 8: 'Ago', 9: 'Set', 10: 'Ott', 11: 'Nov', 12: 'Dic' },
      todayBtnTxt: "Oggi",
      maxYear: 2030
    };

    //attenzione valori presenti anche nel pimus.component.ts del server
    usoValues = [
      'Manutenzione',
      'Manutenzione Facciata',
      'Manutenzione Copertura', 
      'Manutenzione Copertura/Facciate', 
      'Nuova Costruzione',
      'Canna Fumaria',
      'Castelletto di servizio',
      'Servizio Manutenzione Singola Unità',
      'Rimozione Eternit',
      'Opere provvisionali',
    ];

    //attenzione valori presenti anche nel pimus.component.ts del server
    altezzaValues = [
      'Altezza maggiore di 20m',
      'Altezza minore di 20m',
      'Ponteggio minore 20m difforme',
    ];

    protezioneValues=[
      'Mantovana Paraschegge',
      'Segregare area sottostante',
      'Mantovana Paraschegge e Reti Antipolvere ',
      'Segregare area sottostante e Reti Antipolvere',
          ];
    

    editEmployeetypes = true;
    editWorkingtooltypes = true;
    editWorkingtools = true;
    editDocuments = true;
    editEmployees = true;
    editSubappalti = true;

    facciata_condominiale_txt = 'Si tratta di ponteggio da realizzarsi in fregio a facciata condominiale con interferenza su via pubblica, si dovr\à segregare l\'area nella zona di montaggio/smontaggio evitando che persone non adette ai lavori possano transitare nelle zone dulante le fasi di montaggio/smontaggio.\n';
    nuova_costruzione_txt = 'Si tratta di cantiere di nuova costruzione, si utilizzeranno gli ingressi di cantiere e le aree di stoccaggio indicade dall\' impresa principale, ove presente gru i bancali saranno movimentati a cura del gruista \( messo a disposizione dall\' impresa principale\) e posizionati in prossimit\à delle zone d\'utilizzo per poi essere movimentati manualmente. Le fasi di montagio seguiranno l\'evolizione del cantiere.\n';
    carico_scarico_da_strada_txt = 'Le operazioni di carico e scarico del materiale avverranno attraverso la movimentazione degli elementi dall\' autocarro alla posizione di tiro, saranno movimentati gli elementi uno alla volta e, in caso di necessit\à accantonati nell\' area di cantiere per le necessarie sucessive operazioni di tiro.\n';
    scavalchi_sbalzi_in_tubo_giunto_txt = 'Essendo previste porzioni di ponteggio realizzate con uso di elementi in tubo giunto e/o con travi prefabbricate , sar\à necessario procedere creando, ove necessario , strutture provvisionali volte solo alle fasi di montaggio-smontaggio secondo le indicazioni del capocantiere.\n ';
    fondazione_su_copertura_txt = 'Alcune porzioni di ponteggio si eleveranno con gli elementi partenti su tetto, si dovrà pertanto premilinarmente verificare l\'idoneità del supporto e comunque sistemare alla base idonei assi da ponte per la distribuzione del carico. Durante le operazioni in copertura si dovr\à comunque operare in presenza di parapetti o con l\'uso di linea vita precedentemente predisposta.\n ';

    contestoValues = [
      this.facciata_condominiale_txt,
      this.nuova_costruzione_txt,
      this.carico_scarico_da_strada_txt,
      this.scavalchi_sbalzi_in_tubo_giunto_txt,
      this.fondazione_su_copertura_txt,
    ]

    pimus: Pimus;
    securitytasks: any[];
    allSubcompanies: any[];
    
    expiringDays =  '';

    static parameters = [HttpClient, Router, ActivatedRoute];
    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
        this.http = http;
        this.route = route;
    }

    ngOnInit() {
    	this.expiringDays = localStorage.getItem('expiringDays');
        this.route.paramMap.subscribe( params => {
            var id: string = params.get('id');
            this.http.get('/api/pimus/'+id).subscribe((pimus: Pimus) => {
                this.pimus = pimus;
                this.setDate();
                this.setEmployeesInSite();
                this.selectOffSiteEmployees();
                if(!this.pimus.prot){
                  this.pimus.prot = this.pimus.id.toString();
                  this.pimus.rev = '1';
                }
                this.pimus.workingtools.forEach(e =>{
                  this.autoSelectDocuments(e.documents)
                });
                this.updatePimus( () =>{
                  this.autoSelectDocuments(this.pimus.Company.documents);
                  this.pimus.Employees.forEach(e =>{
                    this.autoSelectDocuments(e.documents)
                  })
                  this.updatePimus(false);
                });
                this.http.get('/api/companies/').subscribe( (list: any) => {
                  var list1 = list.filter( o => {return o.subcompany}); //prendo solo le subcompanies
                  var list2 = list1.filter( o => {return o.id_parent_company === this.pimus.Company.id}); //tolgo se stessa
                  this.allSubcompanies = list2;
                });
                this.http.get('/api/securitytasks').subscribe((securitytasks: any[]) => {
                  this.securitytasks = securitytasks;
                  this.updateSecuritytasks();
                })
            });
        });
        if( parseInt( this.expiringDays) <0 ){ 
          alert("PIMUS non modificabile, account scaduto da "+ Math.abs(parseInt(this.expiringDays)) + " giorni.");
        }
    }


  setDate(){
    if(!this.pimus.data_documento){
        this.pimus.data_documento = new Date();
    }else{
        this.pimus.data_documento = new Date(this.pimus.data_documento)
    }
    this.pimus.data_documento_js = { 'date': { year: this.pimus.data_documento.getFullYear(), 'month': this.pimus.data_documento.getMonth() + 1, 'day': this.pimus.data_documento.getDate()} };
  }

  onDocumentDateChanged(event: IMyDateModel) {
    this.pimus.data_documento = event.jsdate ;
    this.pimus.data_documento.setDate( this.pimus.data_documento.getDate() + 1);
    this.updatePimus(false);
  }

    ngOnDestroy() {
    }

    fillContesto(pimus){
      this.pimus.contesto += '\n'+this.contestoValues[pimus];
    }

    onAddressChange( address: string) {
        this.pimus.fulladdress = address;
        this.updatePimus(false);
    }

    onWorkingtoolsSelectedChanges( list: Workingtool[]) {
      this.pimus.workingtools = list;
      this.updatePimus( () =>{
        this.pimus.workingtools.forEach(e =>{
          this.autoSelectDocuments(e.documents)
        });
        this.updatePimus(false);
      });
    }

    onWorkingtooltypesSelectedChanges( list: Workingtooltype[]) {
        this.pimus.Workingtooltypes = list;
        this.updatePimus(false);
    }

    onEmployeetypesSelectedChanges( etsSelected: Employeetype[]) {
        this.pimus.Employeetypes = etsSelected;
        this.updatePimus(false);
    }

    onEmployeesSelectedChanges( eSelected: any[]) {
        this.pimus.Employees = eSelected;
        this.updateSecuritytasks();        
        this.updatePimus( () => {
          this.pimus.Employees.forEach(e =>{
            this.autoSelectDocuments(e.documents)
          })
          this.updatePimus(false);
        });
      }


    hasSecuritytask(employee, securitytask){
      if (employee && employee.Securitytasks && securitytask){
        return this.inArray(employee.Securitytasks, st => {
          return (st.id === securitytask.id);
        });
      }
      else{
        return false;
      }
    }

    autoSelectDocuments(docList){
      if(docList && this.pimus.automate){
        docList.forEach(d => {
          if(!d.deleted){
            d.checked = true;
            this.pimus.documents.push(d);  
          }
        });
      }
    }

    setEmployeesInSite(){
      this.pimus.Company.Employees.forEach(e => {
        this.setEmployeeInSite(e);
      })
      this.pimus.Employees.forEach(e => {
        this.setEmployeeInSite(e);
      })
    }

    setEmployeeInSite(employee: Employee){
      if(employee.type=="Dipendente"){
          employee.in_site = true;
          return;
      }
      if(employee.Securitytasks.length == 0 && employee.datore_lavoro){
          employee.in_site = false;
          return;
      }
      if(employee.Securitytasks.length == 0){
          employee.in_site = true;
          return;
      } else{
          var offSite = true;
          employee.Securitytasks.forEach( st => {
              if(st.in_site){
                  offSite = false;
              }
          })
          employee.in_site = !offSite; 
          return;
      }
    }

    updateSecuritytasks(){
        if(!this.securitytasks){
            return;
        }
        this.setEmployeesInSite();
        this.securitytasks.forEach( st => {
          st.nominatedEmployees = [];
          st.employees = [];
          if(this.pimus.Employees){
              this.pimus.Employees.forEach( e => {
                if(e.Securitytasks){
                    e.Securitytasks.forEach( est => {
                      if(est.id === st.id){
                        var o = {
                          'id' : e.id,
                          'nome' : e.nome,
                          'cognome' : e.cognome,
                          'securitytaskname' : st.name,
                          'securitytaskid' : st.id,
                        }
                        st.nominatedEmployees.push(o as EmployeeInSecuritytask);
                      }
                    });
                 }
               });
          }
        });
    }

    inArray(array, comparer) {
      for(var i=0; i < array.length; i++) {
        if(comparer(array[i])){
          return true;
        }
      }
      return false;
    };

    inArrayById(array, item) {
      for(var i=0; i < array.length; i++) {
        if(array[i].id === item.id){
          return true;
        }
      }
      return false;
    };

    removeElementsDeletedInArray(array) {
      var newArray = [];
      for(var i=0; i < array.length; i++) {
        if(!array[i].deleted){
          newArray.push(array[i]);
        }
      }
      return newArray;
    };

    filterChecked(e: EmployeeInSecuritytask){
      return e.checked;
    }

    filterUnchecked(e: EmployeeInSecuritytask){
      return !e.checked;
    }

    onSecuritytasksSelectedChanges( securitytasks: any[]) {
        this.securitytasks = securitytasks;
        this.updatePimus(false);
    }

    onDocumentsSelectedChanges( documents: any[]) {
        this.pimus.documents = documents;
        this.updatePimus(false);
    }

    doTextareaValueChange(ev, obj) {
        try {
            obj = ev.target.value;
            this.updatePimus(false);
        } catch(e) {
            console.info('could not set textarea-value');
        }
    }
    
    onSubcompaniesSelectedChanges(subcompanies){
        this.pimus.Subcompanies = subcompanies;
        this.updatePimus(false);
    }

    updatePimus(callback){

        this.http.put('/api/pimus/'+this.pimus.id, this.pimus).subscribe( (pimusUpdated: Pimus) => {
            this.pimus.errors = pimusUpdated.errors;
            if(callback){
              this.http.get('/api/pimus/'+this.pimus.id).subscribe((pimus: Pimus) => {
                this.pimus = pimus;
                this.setEmployeesInSite();
                this.selectOffSiteEmployees();
                callback();
              });
            }
        });  
    }
    
    completed(){
      this.pimus.automate = false;
      this.updatePimus(false);
      const url = '/pimusList';
      this.router.navigate([url]);
    }


    selectOffSiteEmployees(){
      this.pimus.Employees = this.removeElementsDeletedInArray(this.pimus.Employees);
      this.pimus.Company.Employees.forEach( companyEmployee => {
        if(!companyEmployee.deleted){
          var isRSPP = this.employeeHasSecurityTask( companyEmployee, 'R.S.P.P.');
          var isRSL = this.employeeHasSecurityTask( companyEmployee, 'R.L.S.');
          var isMedico = this.employeeHasSecurityTask( companyEmployee, 'Medico Competente');
          var isDatore = companyEmployee.datore_lavoro;
          if( (isRSPP  || isRSL || isMedico || isDatore) && !this.inArrayById(this.pimus.Employees, companyEmployee)){
            this.pimus.Employees.push(companyEmployee);
          }
        }
      });
    }

    employeeHasSecurityTask(employee, securitytaskName){
      var out = false;
      employee.Securitytasks.forEach( st => {
        if(st.name == securitytaskName){
          out = true;
        }
      });
      return out;
    }

    delete(){
      var r = confirm("Stai per cancellare un PIMUS. Premi OK per procedere"); 
      if (r == true) {
          this.http.delete('/api/pimus/'+ this.pimus.id).subscribe( () => {
              this.completed();
          });
      } 
    }


    clone(){
      var r = confirm("Stai per clonare il PiMUS. Premi OK per procedere"); 
      if (r == true) {
          this.http.post('/api/pimus', { id_company: this.pimus.Company.id }).subscribe( (pimusNew: Pimus) => {
              console.log('Added Pimus:'+ pimusNew.id);
              this.pimus.prot = ""+pimusNew.id;
              this.pimus.rev = '1';
              this.http.put('/api/pimus/'+pimusNew.id, this.pimus).subscribe( (pimusUpdated: Pimus) => {
                const url = '/pimus/'+pimusNew.id;
                this.router.navigate([url]);
              });
          });
          this.completed();
      } 
    }

    


  notDeleted(list){
    return list.filter(x => !x.deleted);
  }
}
