import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';


interface Securitytask {
    id: number;
    name: string;
    pos_mandatory: boolean;
    pimus_mandatory: boolean;
    nominated: boolean;
}

interface Employee {
    id: number;
    nome: string;
    cognome: string;
    datore_lavoro: boolean;
    Securitytasks: Securitytask[];
    id_company: number;
    nominated: boolean;
    deleted: boolean;
}

interface Company {
    id: number;
    name: string;
    Employees: Employee[];
}

@Component({
    selector: 'employees',
    template: require('./employees.pug'),
    styles: [require('./employees.css')],
})

export class EmployeesComponent implements OnInit, OnDestroy {
    employees: Employee[] = [];
    employeesOfCompany: Employee[] = [];
    deletedEmployeesOfCompany: Employee[] = [];
    
    showDeleted = false;
    
    securitytasks: Securitytask[];
    
    company: Company;

    securitytasksNotCoveredPos: string[] = [];
    securitytasksNotCoveredPimus: string[] = [];
    datoreDiLavoroIsPresent = false;    
    
    static parameters = [HttpClient, Router, AuthService];
    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {
        this.http = http;
        this.authService = authService;
    }

    ngOnInit() {    
        this.http.get('/api/employees').subscribe( (employees: Employee[]) => {
                this.employees = employees;
                this.setCompany( this.authService.getCurrentCompany() );
                this.authService.currentCompanyObservers.subscribe( (company: any) => {
                    this.setCompany( company );
                });
                this.updateSecuritytasks();                
        });
    }

    ngOnDestroy() {
        
    }

    setCompany(company){
        this.company = company;
        this.employeesOfCompany = this.employees.filter(o => {
            return (o.id_company == company.id && !o.deleted);
        })    
        this.deletedEmployeesOfCompany = this.employees.filter(o => {
            return (o.id_company == company.id);
        })    
    }

    addEmployee() {
        var r = confirm("Stai per inserire una nuovo DIPENDENTE/COLLABORATORE. Premi OK per procedere"); 
        if (r == true) {
            return this.http.post('/api/employees', { nome: 'Mario', cognome: 'Rossi', id_company: this.company.id}).subscribe( (e: Employee) => {
                    console.log('Added Employee:', e);
                    const url = '/employee/'+e.id;
                    this.router.navigate([url]);
            });
        }
    }    

    updateSecuritytasks(){
        if(!this.securitytasks){
            this.http.get('/api/securitytasks').subscribe((securitytasks: any[]) => {
                this.securitytasks = securitytasks;
                this.updateSecuritytasks();
            });
            return;
        }
        
        this.datoreDiLavoroIsPresent = false;
        var securitytaskNamesOfAllEmployees = [];

        this.employeesOfCompany.forEach( e => {
            if(e.datore_lavoro){
                this.datoreDiLavoroIsPresent = true;
            }
            e.Securitytasks.forEach( est => {
                securitytaskNamesOfAllEmployees.push(est.name);
            })
        });

        this.securitytasksNotCoveredPos = [];
        this.securitytasksNotCoveredPimus = [];
        
        this.securitytasks.forEach( st => {
            if (st.pos_mandatory && securitytaskNamesOfAllEmployees.indexOf(st.name) < 0 ){
                this.securitytasksNotCoveredPos.push(st.name);
            }
            if (st.pimus_mandatory && securitytaskNamesOfAllEmployees.indexOf(st.name) < 0){
                this.securitytasksNotCoveredPimus.push(st.name);
            }
        })
    }

}
