import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';
import { Router } from '@angular/router';

interface Company {
    id: number;
    name: string;
    public: boolean;
    info?: string;
    id_parent_company: number;
    deleted: boolean;
}


@Component({
    selector: 'companies',
    template: require('./companies.pug'),
    styles: [require('./companies.css')],
})

export class CompaniesComponent implements OnInit, OnDestroy {
    companies: Company[] = [];
    companiesOfCompany: Company[] = [];
    deletedCompaniesOfCompany: Company[] = [];
    company: Company;
    
    showDeleted = false;

    static parameters = [HttpClient, Router, AuthService];
    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {
        this.http = http;
        this.router = router;
        this.authService = authService;
    }

    ngOnInit() {    
        this.http.get('/api/companies').subscribe((companies: Company[]) => {
                this.companies = companies;
                this.setCompany( this.authService.getCurrentCompany() );
                this.authService.currentCompanyObservers.subscribe( (company: any) => {
                    this.setCompany( company );
                });        
        });
    }

    ngOnDestroy() {
    }

    setCompany(company){
        this.company = company;
        this.companiesOfCompany = this.companies.filter(o => {
            return o.id_parent_company == company.id && !o.deleted;
        })    
        this.deletedCompaniesOfCompany = this.companies.filter(o => {
            return o.id_parent_company == company.id;
        })    
    }

    addCompany() {
        var r = confirm("Stai per inserire una nuova IMPRESA in subappalto. Premi OK per procedere"); 
        if (r == true) {
            var obj = {}
            if(this.company){
                obj = { name: 'NUOVA IMPRESA IN SUBAPPALTO', id_parent_company: this.company.id, subcompany:true };
            }else{
                return;
            }
            return this.http.post('/api/companies', obj).subscribe( (companyCreated: Company) => {
                    console.log('Added Company:', companyCreated);
                    const url = '/company/'+companyCreated.id;
                    this.router.navigate([url]);
            });
        }
    }
}
