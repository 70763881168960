import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';


interface Phase {
    name: string;
    public: boolean;
    info?: string;
    deleted: boolean;
}


@Component({
    selector: 'phases',
    template: require('./phases.pug'),
    styles: [require('./phases.css')],
})

export class PhasesComponent implements OnInit, OnDestroy {
    SocketService;
    phases: Phase[] = [];
    deletedPhases: Phase[] = [];

    isSortedByName = true;
    isSortedByType = false;
    
    selectedPhase: Phase;
    newPhase = '';

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    ngOnInit() {    
        this.http.get('/api/phases')
            .subscribe((phases: Phase[]) => {
                this.phases = phases.filter(o => {
                    return (!o.deleted);
                })    
                this.deletedPhases = phases.filter(o => {
                    return (o.deleted);
                })    
            });
    }

    ngOnDestroy() {
        //this.SocketService.unsyncUpdates('phase');        
    }

    addPhase() {
        if(this.newPhase) {
            let text = this.newPhase;
            this.newPhase = '';

            return this.http.post('/api/phases', { name: text })
                .subscribe(phase => {
                    console.log('Added Phase:', phase);
                    
                    this.phases.push(phase as Phase);
                    this.selectedPhase = phase as Phase;
                });
        }
    }

    deletePhase(phase) {
        return this.http.delete(`/api/phases/${phase._id}`)
            .subscribe(() => {
                console.log('Deleted Phase');
            });
    }


    onSortByName(){
        this.phases = this.sortByName(this.phases);
        this.deletedPhases = this.sortByName(this.deletedPhases);
        this.isSortedByName = true;
        this.isSortedByType = false;
    }

    onSortByType(){
        this.phases = this.sortByType(this.phases);
        console.log(this.phases);
        this.deletedPhases = this.sortByType(this.deletedPhases);
        this.isSortedByName = false;
        this.isSortedByType = true;
    }

    sortByName(o){
        return o.sort( (a,b) => { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()) });
    }
    
    sortByType(o){
        return o.sort( (a,b) => { return a.Phasetype.name.toLowerCase().localeCompare( b.Phasetype.name.toLowerCase() ) });
    }
    
}
