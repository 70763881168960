import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { DirectivesModule } from '../../components/directives.module';

import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { CountermeasuresPageComponent } from './countermeasures.component';
import { SocketService } from '../../components/socket/socket.service';

export const ROUTES: Routes = [
    { path: 'countermeasures', component: CountermeasuresPageComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        DirectivesModule,
        VirtualScrollerModule,

    ],
    declarations: [
        CountermeasuresPageComponent,
    ],
    providers: [
        SocketService,
    ],
    exports: [
        CountermeasuresPageComponent,
    ],
})
export class CountermeasuresPageModule {}
