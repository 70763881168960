import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Workingtooltype {
    id: number;
    name: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    checked: boolean;
}

@Component({
    selector: 'workingtooltypes-as-checkboxes',
    template: require('./workingtooltypes-as-checkboxes.pug'),
    styles: [require('./workingtooltypes-as-checkboxes.css')],
})

export class WorkingtooltypesAsCheckboxesComponent implements OnInit, OnDestroy {

    @Input()  input: Workingtooltype[];
    @Output() selectedChanges = new EventEmitter<Workingtooltype[]>();

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;

    selectedworkingtooltypes: Workingtooltype[];
    workingtooltypes: Workingtooltype[];

    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route;
        this.authService = authService;

        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    ngOnInit() {
    }

    changeCheckbox(Workingtooltype) {
        Workingtooltype.checked = !Workingtooltype.checked;
        if(Workingtooltype.checked){
          this.selectedworkingtooltypes.push(Workingtooltype)
        }else{
          this.selectedworkingtooltypes = this.workingtooltypes.filter( d => {
              return d.checked;
          });
        }
        this.selectedChanges.emit(this.selectedworkingtooltypes);
    }

    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.input;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.selectedworkingtooltypes = change.currentValue

      this.route.queryParams.subscribe(params => {
          this.http.get('/api/workingtooltypes/').subscribe( (workingtooltypesLoaded: Workingtooltype[]) => {
              this.workingtooltypes = workingtooltypesLoaded;
              this.selectedworkingtooltypes.forEach( ds => {
                this.workingtooltypes.forEach( d => {
                  if(d.id == ds.id){
                    d.checked=true;
                  }
                });
              });

          });
      });
    }


    ngOnDestroy() {
    }

}
