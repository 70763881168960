import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../components/socket/socket.service';

interface Phasetype {
    name: string;
    public: boolean;
    info?: string;
}


@Component({
    selector: 'phasetypes',
    template: require('./phasetypes.pug'),
    styles: [require('./phasetypes.css')],
})

export class PhasetypesComponent implements OnInit, OnDestroy {
    SocketService;
    phasetypes: Phasetype[] = [];
    
    selectedPhasetype: Phasetype;
    newPhasetype = '';

    static parameters = [HttpClient, SocketService];
    constructor(private http: HttpClient, private socketService: SocketService) {
        this.http = http;
        this.SocketService = socketService;
    }

    ngOnInit() {    
        this.http.get('/api/phasetypes')
            .subscribe((phasetypes: Phasetype[]) => {
                this.phasetypes = phasetypes;
                this.SocketService.syncUpdates('phasetype', this.phasetypes);
            });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('phasetype');        
    }

    addPhasetype() {
        if(this.newPhasetype) {
            let text = this.newPhasetype;
            this.newPhasetype = '';

            return this.http.post('/api/phasetypes', { name: text })
                .subscribe(phasetype => {
                    console.log('Added Phasetype:', phasetype);
                    
                    this.phasetypes.push(phasetype as Phasetype);
                    this.selectedPhasetype = phasetype as Phasetype;
                });
        }
    }

    deletePhasetype(phasetype) {
        return this.http.delete(`/api/phasetypes/${phasetype._id}`)
            .subscribe(() => {
                console.log('Deleted Phasetype');
            });
    }
}
