import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Workingtooltype {
    id: number;
    name: string;
    id_user: number;
    workingtools: Workingtool[];
}

interface Workingtool {
    id: number;
    name: string;
    id_user: number;
    selected: boolean;
    Workingtooltype: any;
}

@Component({
    selector: 'workingtools-as-checkboxes',
    template: require('./workingtools-as-checkboxes.pug'),
    styles: [require('./workingtools-as-checkboxes.css')],
})

export class WorkingtoolsAsCheckboxesComponent {

    @Input() selectedWorkingtools: Workingtool[]; 
    @Input() workingtooltypes: Workingtooltype[]; 
    @Output() selectedChanges = new EventEmitter<Workingtool[]>();

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });
        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    changeCheckbox(workingtool) {
        workingtool.selected = !workingtool.selected;
        this.updateSelections();
        this.selectedChanges.emit(this.selectedWorkingtools);
    }    

    updateSelections(){
        var list = [];
        this.workingtooltypes.forEach( wtt => {
            wtt.workingtools.forEach( wt => {
                if(wt.selected){
                    wt.Workingtooltype = { name: wtt.name};
                    list.push(wt);
                }
            })    
        });
        this.selectedWorkingtools = list;
        
    }

    ngOnChanges(changes: SimpleChanges) {
        const selectedWorkingtoolsChange: SimpleChange = changes.selectedWorkingtools;
        this.selectedWorkingtools = selectedWorkingtoolsChange.currentValue

        const workingtooltypesChange: SimpleChange = changes.workingtooltypes;
        if(workingtooltypesChange){
            this.workingtooltypes = workingtooltypesChange.currentValue;
            this.updateSelectionsFromSelected();
        }
    }

    updateSelectionsFromSelected(){
        this.workingtooltypes.forEach( wtt => {
            this.selectedWorkingtools.forEach( wt1 => {
                wtt.workingtools.forEach( wt2 => {
                    if(wt1.id == wt2.id){
                        wt2.selected = true; 
                    }
                })
            })    
        })
    }

}