import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';
import { AuthModule } from './auth/auth.module';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { OauthButtonsComponent } from './oauth-buttons/oauth-buttons.component';

import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AgmCoreModule } from '@agm/core';


import { PhasetypeComponent } from './phasetype/phasetype.component';
import { PhaseComponent } from './phase/phase.component';
import { SubphaseComponent } from './subphase/subphase.component';

import { RiskComponent } from './risk/risk.component';
import { DpiComponent } from './dpi/dpi.component';
import { EmployeetypeComponent } from './employeetype/employeetype.component';
import { WorkingtooltypeComponent } from './workingtooltype/workingtooltype.component';
import { SectorComponent } from './sector/sector.component';
import { CountermeasureComponent } from './countermeasure/countermeasure.component';

import { WorkingtooltypesAsCheckboxesComponent } from './workingtooltypes-as-checkboxes/workingtooltypes-as-checkboxes.component';
import { WorkingtoolsAsCheckboxesComponent } from './workingtools-as-checkboxes/workingtools-as-checkboxes.component';
import { EmployeetypesAsCheckboxesComponent } from './employeetypes-as-checkboxes/employeetypes-as-checkboxes.component';
import { EmployeesAsCheckboxesComponent } from './employees-as-checkboxes/employees-as-checkboxes.component';
import { DocumentsAsCheckboxesComponent } from './documents-as-checkboxes/documents-as-checkboxes.component';
import { SecuritytasksAsCheckboxesComponent } from './securitytasks-as-checkboxes/securitytasks-as-checkboxes.component';
import { RisksAsCheckboxesComponent } from './risks-as-checkboxes/risks-as-checkboxes.component';
import { DpisAsCheckboxesComponent } from './dpis-as-checkboxes/dpis-as-checkboxes.component';
import { CompaniesAsCheckboxesComponent } from './companies-as-checkboxes/companies-as-checkboxes.component';
import { PhaseEditComponent } from './phase-edit/phase-edit.component';
import { SubphaseEditComponent } from './subphase-edit/subphase-edit.component';


import { SectorAsSelectComponent } from './sector-as-select/sector-as-select.component';
import { PhasetypeAsSelectComponent } from './phasetype-as-select/phasetype-as-select.component';
import { CompanyAsSelectComponent } from './company-as-select/company-as-select.component';

import { AddressComponent } from './address/address.component';

import { CompanyComponent } from './company/company.component';

import { PhasesAsCheckboxesComponent } from './phases-as-checkboxes/phases-as-checkboxes.component';
import { SubphasesAsCheckboxesComponent } from './subphases-as-checkboxes/subphases-as-checkboxes.component';


//TOADD

@NgModule({
    imports: [
        CommonModule,
        CollapseModule,
        AuthModule,
        RouterModule,
        FormsModule,
        VirtualScrollerModule,
        AgmCoreModule,
    ],
    declarations: [
        NavbarComponent,
        FooterComponent,
        OauthButtonsComponent,
        PhasetypeComponent,
        PhaseComponent,
        SubphaseComponent,
        RiskComponent,
        DpiComponent,
        EmployeetypeComponent,
        WorkingtooltypeComponent,
        SectorComponent,
        CountermeasureComponent,
        DpisAsCheckboxesComponent,
        WorkingtooltypesAsCheckboxesComponent,
        WorkingtoolsAsCheckboxesComponent,
        EmployeetypesAsCheckboxesComponent,
        EmployeesAsCheckboxesComponent,
        DocumentsAsCheckboxesComponent,
        SecuritytasksAsCheckboxesComponent,
        RisksAsCheckboxesComponent,
        DpisAsCheckboxesComponent,
        SectorAsSelectComponent,
        PhasetypeAsSelectComponent,
        AddressComponent,
        CompanyComponent,
        PhasesAsCheckboxesComponent,
        SubphasesAsCheckboxesComponent,
        CompaniesAsCheckboxesComponent,
        CompanyAsSelectComponent,
        PhaseEditComponent,
        SubphaseEditComponent
        //TOADD
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
        OauthButtonsComponent,
        PhasetypeComponent,
        PhaseComponent,
        SubphaseComponent,
        RiskComponent,
        DpiComponent,
        EmployeetypeComponent,
        WorkingtooltypeComponent,
        CountermeasureComponent,
        SectorComponent,
        DpisAsCheckboxesComponent,
        WorkingtooltypesAsCheckboxesComponent,
        WorkingtoolsAsCheckboxesComponent,
        EmployeetypesAsCheckboxesComponent,
        EmployeesAsCheckboxesComponent,
        DocumentsAsCheckboxesComponent,
        SecuritytasksAsCheckboxesComponent,
        RisksAsCheckboxesComponent,
        DpisAsCheckboxesComponent,
        SectorAsSelectComponent,
        PhasetypeAsSelectComponent,
        AddressComponent,
        CompanyComponent,
        PhasesAsCheckboxesComponent,
        SubphasesAsCheckboxesComponent,
        CompaniesAsCheckboxesComponent,
        CompanyAsSelectComponent,
        PhaseEditComponent,
        SubphaseEditComponent
        //TOADD
    ]
})
export class DirectivesModule {}
