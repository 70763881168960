// @flow
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';

interface User {
    name: string;
    email: string;
    password: string;
}

@Component({
    selector: 'login',
    template: require('./login.pug'),
})
export class LoginComponent {
    user: User = {
        name: '',
        email: '',
        password: '',
    };
    errors = {login: undefined};
    submitted = false;
    AuthService;
    Router;
    emailSentMessage;


    static parameters = [HttpClient, AuthService, Router];
    constructor(private http: HttpClient, _AuthService_: AuthService, router: Router) {
        this.AuthService = _AuthService_;
        this.Router = router;
    }

    login(form) {
        if(form.invalid) return;

        return this.AuthService.login({
            email: this.user.email,
            password: this.user.password
        })
            .then(() => {
                // Logged in, redirect to home
                this.Router.navigateByUrl('/welcome');

            })
            .catch(err => {
                if(err.status=401){
                    this.errors.login = 'ACCESSO NON AUTORIZZATO: email non registrata o credenziali di accesso non corrette.'   
                }
                //this.errors.login = err.error.message;
            });
    }


    passwordReset() {
        return this.http.post('/api/users/emailresetpassword', { email: this.user.email }).subscribe( () => {
            console.log('Email sent');
            this.emailSentMessage = "Ti abbiamo scritto, controlla la tua email "+this.user.email
        });

    }


}
