import { Component, ElementRef, NgZone, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { FormControl } from "@angular/forms";
import { MapsAPILoader } from '@agm/core';

declare var google;

@Component({
    selector: 'address',
    template: require('./address.pug'),
    styles: [require('./address.css')],
})
export class AddressComponent implements OnInit {

    @Input() address: string;
    @Output() addressChanges = new EventEmitter<string>();

    public notStandardAddress : boolean;
    public autocomplete: any;
    public latitude: number;
    public longitude: number;
    public searchControl: FormControl;
    public zoom: number;
    public center: { lat:number, lng: number};

    @ViewChild("search")
    public searchElementRef: ElementRef;

    static parameters = [MapsAPILoader, NgZone];
    private geocoder: any;

    constructor(
      private mapsAPILoader: MapsAPILoader,
      private ngZone: NgZone
    ) {}

    ngOnInit() {
      this.notStandardAddress = false;
      //set google maps defaults
      this.zoom = 17;
      this.latitude = 39.8282;
      this.longitude = -98.5795;
      this.center = {lat: this.latitude, lng: this.longitude};
      //create search FormControl
      this.searchControl = new FormControl();


      //load Places Autocomplete
      this.mapsAPILoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();

        this.geocodeAddress(this.address)

        this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          //types: ["address"]
        });
        this.autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.address = place.formatted_address

            // set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.center = {lat: this.latitude, lng: this.longitude};

            this.zoom = 17;

            this.addressChanges.emit(this.address);
          });
        });
      });
    }

    changeNotStandardAddress(){
      this.notStandardAddress = true;
      this.autocomplete.unbindAll();
      google.maps.event.clearInstanceListeners(this.searchElementRef.nativeElement);
    }

    changeAddress(){
      if(this.notStandardAddress){
        // se non e' standard aggiorno senza autocomplete
        this.addressChanges.emit(this.address);
      }
    }


    private setCurrentPosition() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.center = {lat: this.latitude, lng: this.longitude};
          this.zoom = 17;
        });
      }
    }

    private geocodeAddress(location: string) {
      console.log('Start geocoding!');
      this.geocoder.geocode({'address': location}, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            console.log('Geocoding complete!');
            this.latitude = results[0].geometry.location.lat();
            this.longitude = results[0].geometry.location.lng();
          } else {
              console.log('Error - ', results, ' & Status - ', status);
              //set current position
              this.setCurrentPosition();
          }
      });
    }


}
