import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';


interface Document {
    id: number;
    type: string;
}

interface Employee {
    id: number;
    name: string;
}

interface Sector {
    id: number;
    name: string;
}

interface Workingtooltype {
  id: number;
  name: string;
}

interface Employeetype {
  id: number;
  name: string;
}

interface Company {
    id: number;
    name: string;
    description: string;
    addressSedeLegale: string;
    addressSedeOperativa: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    subcompany: boolean;
    Sector: Sector;
    ParentCompany: Company;
    Workingtooltypes: Workingtooltype[];
    Employeetypes: Employeetype[];
    Employees: Employee[];
    Subcompanies: Company[];
    documents: Document[];
    errors: string[];
    valid: boolean;
}


@Component({
    selector: 'Company',
    template: require('./company.pug'),
    styles: [require('./company.css')],
})
export class CompanyComponent implements OnInit {

    company: Company;
    companies: Company[];
    editEmployeetypes = true;
    editWorkingtooltypes = true;
    
    documentTypes=[
        'DURC',
        'VISURA CAMERALE',       
    ]

    types=[
        'Impresa',
        'Lavoratore autonomo',       
    ]

    documentTypesNotCovered: string[];
    
    errors: {field?: Error} = {};
    submitted = false;
    expiringDays = '';
    
    static parameters = [HttpClient, Router, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private authService: AuthService) {
        this.http = http;
        this.router = router;
    }

    ngOnInit() {    
        this.expiringDays = localStorage.getItem('expiringDays');
        this.route.paramMap.subscribe( params => {
            var id: string = params.get('id');
            this.http.get('/api/companies/'+id).subscribe( (c: Company) => {
                this.http.get('/api/companies').subscribe( (list: Company[]) => {
                    var list1 = list.filter(o => { return (o.id != c.id) }); //escludo se stessa dalla lista di possibili parentCompany
                    var list2 = list1.filter(o => { return (!o.subcompany) }); //escludo le imprese in subappalto
                    this.companies = list2;
                    this.company = c;
		            if(!this.company.subcompany){
                       this.authService.setCurrentCompany(c);
		            }
                    this.updateDocumentTypesNotCovered()
                });
            });
        });
	if( parseInt( this.expiringDays) <0 ){
          alert("Impresa non modificabile, account scaduto da "+ Math.abs(parseInt(this.expiringDays)) + " giorni.");
        }
    }

    updateCompany() {
        this.http.put('/api/companies/'+this.company.id, this.company).subscribe( (c: Company) => {
            console.log('Updated Company!');
            this.company.errors = c.errors;
            this.company.valid = c.valid;
            if(!this.company.subcompany){
                this.authService.setCurrentCompany(c);
            }
        });
    }

    deleteCompany(company) {
        return this.http.delete(`/api/companies/${company._id}`)
            .subscribe(() => {
                console.log('Deleted Company');
            });
    }

    onSectorChange( sectorSelected: Sector) {
        console.log("CompanyComponent -- onSectorChange");
        if(sectorSelected){
            console.log(sectorSelected.name);
        }else{
            console.log("settore non selezionato");
        }
        this.company.Sector = sectorSelected;
        this.updateCompany();
    }

    onAddressSedeLegaleChange( address: string) {
        this.company.addressSedeLegale = address;
        this.updateCompany();
    }

    onAddressSedeOperativaChange( address: string) {
        this.company.addressSedeOperativa = address;
        this.updateCompany();
    }


    onWorkingtooltypesSelectedChanges( wtsSelected: Workingtooltype[]) {
        this.company.Workingtooltypes = wtsSelected;
        this.updateCompany();
    }

    onEmployeetypesSelectedChanges( etsSelected: Employeetype[]) {
        this.company.Employeetypes = etsSelected;
        console.log(etsSelected);
        this.updateCompany();
    }


    onSubcompanyFlagChange(isSubcompany){
        if(isSubcompany){
            this.company.Subcompanies = [];
            this.company.Employees = [];
            this.company.Employeetypes = [];
            this.company.Workingtooltypes = [];
        }else{
            this.company.ParentCompany = null;
        }
        this.company.subcompany = isSubcompany;
        this.updateCompany();
    }

    onParentCompanyChange(c : Company) {
        console.log("onParentCompanyChanges");
        console.log(c);
        this.company.ParentCompany = c;
        this.updateCompany();
    }

    completed(){
        if(this.company.subcompany){
            const url = '/companies';
            this.router.navigate([url]);
        }
        else{
            const url = '/welcome';
            this.router.navigate([url]);
        }

    }

    updateDocumentTypesNotCovered(){
        var documentTypesPresent = [];
        this.company.documents.forEach(d => {
            documentTypesPresent.push(d.type);
        });

        this.documentTypesNotCovered = [];
        this.documentTypes.forEach(a => {
            if( documentTypesPresent.indexOf(a) < 0 ){
                this.documentTypesNotCovered.push( a );
            }
        });
    }
    
    addCompanyDocument(){
        return this.http.post('/api/documents', { type:'DA DEFINIRE', filename: 'NUOVO FILE', data_documento: '2020-01-01', id_company: this.company.id}).subscribe( (d: Document) => {
                console.log('Added Document:', d);
                const url = '/document/'+d.id;
                this.router.navigate([url]);
        });
    }


    delete(){
        var r = confirm("Stai per cancellare una impresa. Premi OK per procedere"); 
        if (r == true) {
            this.http.delete('/api/companies/'+ this.company.id).subscribe( () => {
                this.completed();
            });
        } 
    }
}
