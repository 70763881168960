import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';

interface Risk {
    id: number;
    name: string;
    public: boolean;
    info?: string;
}


@Component({
    selector: 'risks',
    template: require('./risks.pug'),
    styles: [require('./risks.css')],
})

export class RisksComponent implements OnInit, OnDestroy {
    //SocketService;
    risks: Risk[] = [];

    static parameters = [HttpClient, Router];
    constructor(private http: HttpClient, private router: Router,) {
    }

    ngOnInit() {    
        this.http.get('/api/risks')
            .subscribe((risks: Risk[]) => {
                this.risks = risks;
                //this.SocketService.syncUpdates('risk', this.risks);
            });
    }

    ngOnDestroy() {
        //this.SocketService.unsyncUpdates('risk');        
    }

    addRisk() {
        return this.http.post('/api/risks', {name:'nuovo rischio', public: false, deleted: false})
            .subscribe((riskCreated: Risk) => {
                console.log('Added Risk');
                const url = '/adminrisk/'+riskCreated.id;
                this.router.navigate([url]);
            });
    }
}
