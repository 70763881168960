import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Company {
    id: number;
    name: string;
}

@Component({
    selector: 'company-as-select',
    template: require('./company-as-select.pug'),
    styles: [require('./company-as-select.css')],
})

export class CompanyAsSelectComponent{

    @Input() company: Company; 
    @Input() companies: Company[]; 
    @Output() companyChanges = new EventEmitter<Company>();

    selectedCompany: Company; 
    allCompanies: Company[]; 
    
    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    onCompanyChange(company) {
        console.log("CompanyAsSelectComponent -- onCompanyChange");
        this.selectedCompany = company;
        console.log(this.selectedCompany);
        this.companyChanges.emit(this.selectedCompany);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("CompanyAsSelectComponent --- ngOnChanges")
        this.allCompanies = changes.companies.currentValue;
        console.log(this.allCompanies);
        this.selectedCompany = changes.company.currentValue;
        console.log(this.selectedCompany);
    }

    compareById(o1, o2) {
        return o1 && o2 && o1.id == o2.id;
    }

}
