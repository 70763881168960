import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../components/directives.module';

import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { SignupComponent } from './signup/signup.component';
import { PasswordResetComponent } from './passwordReset/passwordReset.component';

import { NavbarComponent } from '../../components/navbar/navbar.component';

const accountRoutes: Routes = [
    {   path: 'login',
        component: LoginComponent,
    }, 
    {   path: 'settings',
        component: SettingsComponent,
    }, 
    {   path: 'signup',
        component: SignupComponent,
    }, 
    {   path: 'passwordReset',
        component: PasswordResetComponent,
    }
];

@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        RouterModule.forChild(accountRoutes),
        DirectivesModule,
    ],
    providers: [
	   NavbarComponent,	
    ],
    declarations: [
        LoginComponent,
        SignupComponent,
        SettingsComponent,
        PasswordResetComponent,
    ],
})
export class AccountModule {}
