import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';

/*
+---------------+-------------+------+-----+---------+----------------+
| Field         | Type        | Null | Key | Default | Extra          |
+---------------+-------------+------+-----+---------+----------------+
| id            | int(11)     | NO   | PRI | NULL    | auto_increment |
| id_user       | int(11)     | YES  | MUL | NULL    |                |
| id_company    | int(11)     | YES  | MUL | NULL    |                |
| id_employee   | int(11)     | YES  | MUL | NULL    |                |
| type          | varchar(60) | YES  |     | NULL    |                |
| filename      | varchar(60) | YES  |     | NULL    |                |
| path          | varchar(60) | YES  |     | NULL    |                |
| workingtool_date | date        | YES  |     | NULL    |                |
| deleted       | char(1)     | YES  |     | 0       |                |
+---------------+-------------+------+-----+---------+----------------+

+--------------------------+
| type                     |
+--------------------------+
| ALTRI workingtoolUMENTI          |
| ASSUNZIONE               |
| ATTESTATO DI FORMAZIONE  |
| ATTESTATO FORMAZIONE DPI |
| ATTESTATO PREPOSTO       |
| ATTESTATO R.S.P.P.       |
| CAMERALE                 |
| COMUNICAZIONE DISTACCO   |
| CONSEGNA DPI             |
| CORSO ANTI INCENDIO      |
| CORSO APP. SOLLEVAMENTO  |
| CORSO PRIMO SOCCORSO     |
| DA DEFINIRE              |
| workingtoolUMENTO DI IDENTITA'   |
| DURC                     |
| FORMAZIONE RLS           |
| IDONEITA' MANSIONE       |
| MANUALISTICA             |
| NOMINA MEDICO COMPETENTE |
| NOMINA R.S.P.P.          |
| NOMINA RLS               |
| PATENTINO PONTEGGIO      |
| PERMESSO DI SOGGIORNO    |
| UNIFICATO LAV DISTACCO   |
| VISURA CAMERALE          |
+--------------------------+
*/


interface Workingtooltype {
    name:string;
}

interface Workingtool {
    id: number;
    id_user: number;
    id_company: number;
    id_employee: number;
    type: string;
    filename: string;
    path: string;
    workingtool_date: string;
    deleted: boolean;
    Workingtooltype: Workingtooltype;

}

interface Header {
    name: string;
    value: string;
}

@Component({
    selector: 'Workingtool',
    template: require('./workingtool.pug'),
    styles: [require('./workingtool.css')],
})
export class WorkingtoolComponent implements OnInit, AfterViewInit {

    types=[
        'ALTRI workingtoolUMENTI',        
        'ASSUNZIONE',
        'ATTESTATO DI FORMAZIONE',
        'ATTESTATO FORMAZIONE D.P.I.',
        'ATTESTATO PREPOSTO',
        'ATTESTATO R.S.P.P.',
        'CAMERALE',
        'COMUNICAZIONE DISTACCO',
        'CONSEGNA D.P.I.',
        'CORSO ANTI INCENDIO',    
        'CORSO APP. SOLLEVAMENTO',
        'CORSO PRIMO SOCCORSO',
        'DA DEFINIRE',            
        "workingtoolUMENTO DI IDENTITA'",
        'DURC',
        'FORMAZIONE R.L.S.',
        "IDONEITA' MANSIONE",
        'MANUALISTICA',
        'NOMINA MEDICO COMPETENTE',
        'NOMINA R.S.P.P.',        
        'NOMINA R.L.S.',
        'PATENTINO PONTEGGIO',    
        'PERMESSO DI SOGGIORNO',  
        'UNIFICATO LAV DISTACCO',
        'VISURA CAMERALE',       
    ]
 
    workingtool: Workingtool;
    
    uploader: FileUploader = new FileUploader({url: '/api/workingtools/0/upload'});
    
    hasDropZoneOver: boolean;
    
    response: string;

    errors: {field?: Error} = {};
    submitted = false;
    expiringDays = '';
    
    static parameters = [HttpClient, Router, ActivatedRoute, ElementRef];
    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private el: ElementRef) {
        this.http = http;
        this.router = router;


        this.uploader.onAfterAddingFile = (fileItem) => {
            fileItem.withCredentials = false;
            const img = document.getElementById('preview');
            img['src'] = '/assets/images/clock.png';
        };

        this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
            console.log("ImageUpload:uploaded:", item, status, response);
            if(item.file.name){
                this.workingtool.filename = item.file.name; 
                this.downloadPreview( this.workingtool );
                this.updateWorkingtool();
            }
        };
    }


    ngOnInit() {    
        this.expiringDays = localStorage.getItem('expiringDays');
        if( parseInt( this.expiringDays) <0 ){
          alert("Attrezzatura non modificabile, account scaduto da "+ Math.abs(parseInt(this.expiringDays)) + " giorni.");
        }

    }

    ngAfterViewInit() {
        return this.route.paramMap.subscribe( params => {
            var id: string = params.get('id');
            return this.http.get('/api/workingtools/'+id).subscribe( (d: Workingtool) => {
                this.workingtool = d;
                var token = localStorage.getItem('id_token');
                this.uploader.setOptions({ url: '/api/workingtools/'+this.workingtool.id+'/upload', autoUpload: true, headers: [{name: 'Authorization', value: 'Bearer ' + token}] });
                this.downloadPreview( this.workingtool );
            });
        });
    }

    public fileOver(e:any):void {
        this.hasDropZoneOver = e;
    }

    
    updateWorkingtool() {
        this.http.put('/api/workingtools/'+this.workingtool.id, this.workingtool).subscribe( () => {
            console.log('Updated Workingtool!');
        });
    }

    
    downloadWorkingtool(workingtool) {
        workingtool.downloading = true;
        workingtool.error = false;
        this.http.get('/api/workingtools/'+workingtool.id+'/download', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "workingtool_"+workingtool.id+".pdf";
            link.click();
            workingtool.downloading = false;
            workingtool.error = false;
        }, error => {
            workingtool.downloading = false;
            workingtool.error = true;
        });
    }

    downloadPreview(workingtool) {
        this.http.get('/api/workingtools/'+workingtool.id+'/preview.png', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'image/png'});
            const img = document.getElementById('preview');
            img['src'] = window.URL.createObjectURL(data);
        }, error => {
            console.log(error);
        });
    }   
    
    completed(){
        const url = '/workingtools';
        this.router.navigate([url]);
    }    


    delete(){
        var r = confirm("Stai per cancellare un'attrezzatura. Premi OK per procedere"); 
        if (r == true) {
            this.http.delete('/api/workingtools/'+ this.workingtool.id).subscribe( () => {
                this.completed();
            });
        } 
    }
}
