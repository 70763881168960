import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AnyARecord } from 'dns';

interface Phase {
  id: number;
  id_parent: number;
  name: string;
  description: string;
  isEditing: boolean;
  Subphases: any[];
  public: boolean;
  checked: boolean;
  deleted: boolean;
  draft: boolean;
  Phasetype: any;
}

interface Subphase {
  id: number;
  name: string;
  description: string;
  isEditing: boolean;
  Risks: any[];
}

@Component({
    selector: 'phase',
    template: require('./phase.pug'),
    styles: [require('./phase.css')],
})

export class PhaseComponent implements OnInit, OnDestroy {

  phase: Phase;
  
  static parameters = [HttpClient, Router,  ActivatedRoute];
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
      this.http = http;
      this.route = route;
  }

  ngOnInit() {
      this.route.paramMap.subscribe( params => {
          var id: string = params.get('id');
          this.http.get('/api/phases/'+id).subscribe((phase: Phase) => {
              this.phase = phase;
          });
      });
  }

  ngOnDestroy() {
  }


  inArray(array, comparer) {
    for(var i=0; i < array.length; i++) {
      if(comparer(array[i])){
        return true;
      }
    }
    return false;
  };

  inArrayById(array, item) {
    for(var i=0; i < array.length; i++) {
      if(array[i].id === item.id){
        return true;
      }
    }
    return false;
  };

  deleteSubphase(phase: any, subphase: any){
    phase.Subphases = this.arrayRemove(phase.Subphases, subphase);
    this.http.delete('/api/subphases/'+subphase.id, subphase).subscribe( (subphaseDeleted) => {
         console.log('Deleted subphase:', subphaseDeleted);
         this.http.put('/api/phases/'+phase.id, phase).subscribe( (phaseUpdated) => {
           console.log('Updated phase:', phaseUpdated);
         });          
    });  
  }

  addSubphase(phase: Phase){
    const subphase = {id_parent: 20};
    this.http.post('/api/subphases/', subphase).subscribe( (subphaseCreated: any)  => {
        console.log('Created subphase:', subphaseCreated);
        subphaseCreated.isEditing = false;
        subphaseCreated.Risks = [];
        phase.Subphases.push(subphaseCreated);
        this.updatePhase();
    });  
  }

  editSubphase(phase: Phase, subphase: Subphase){
      if(subphase.isEditing){
          this.http.put('/api/subphases/'+subphase.id, subphase).subscribe( (subphaseUpdated: any) => {
             console.log('Updated Subphase:', subphaseUpdated);
             subphase.isEditing = false;
          });  
      }else{
        subphase.isEditing = true;
      }
  }
  
  arrayRemove(array, o) {
       return array.filter(e => {
           return e.id != o.id 
       });
  }
 

  onAddedSubphase( phase, subphase: any) {
    this.http.post('/api/subphases/', {id_parent: subphase.id}).subscribe( (subphaseCreated: any)  => {
        console.log('Created subphase:', subphaseCreated);
        subphaseCreated.isEditing = false;
        phase.Subphases.push(subphaseCreated);
        this.updatePhase();
    });  
  }

  editPhase(phase: Phase){
      if(phase.isEditing){
          this.http.put('/api/phases/'+phase.id, phase).subscribe( () => {
              console.log('Updated phase');
              phase.isEditing = false;
          });  
      }else{
          phase.isEditing = true;
      }
  }

  deletePhase(phase: any){
      this.http.delete('/api/phases/'+this.phase.id+'/phases/'+phase.id).subscribe( (phase: any) => {
          console.log("phase e fase disassociati");
          //this.loadPhases();
      });
  }
    
  updatePhase(){
      this.http.put('/api/phases/'+this.phase.id, this.phase).subscribe( () => {
          console.log('Updated phase');
      });  
  }
    
    
  
  completed(){
    const url = '/adminphases';
    this.router.navigate([url]);
  }

  delete(){
    this.phase.deleted = !this.phase.deleted;
    this.updatePhase()
  }

  public(){
    this.phase.public = !this.phase.public;
    this.updatePhase()
  }

  check(){
    this.phase.checked = !this.phase.checked;
    this.updatePhase()
  }

  draft(){
    this.phase.draft = !this.phase.draft;
    this.phase.checked = false;
    this.phase.public = false;
    this.updatePhase()
  }

  clone() {
    this.http.post('/api/phases/', {id_parent: this.phase.id} ).subscribe( (phaseCreated: any)  => {
        const url = '/adminphase/'+phaseCreated.id;
        this.router.navigate([url]);
    })
  }

  showParent() {
    const url = '/adminphase/'+ this.phase.id_parent;
    this.router.navigate([url]);
  }


  moveSubphase(oldIndex, newIndex){
    this.array_move( this.phase.Subphases, oldIndex, newIndex);
    this.updatePhase();
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  };

  onEmployeetypesOfPhaseSelectedChanges(phase, employeetypes){
    phase.Employeetypes = employeetypes;
    this.http.put('/api/phases/'+phase.id+'/employeetypes', phase ).subscribe( (phaseUpdated: any)  => {
        console.log(" ---- employees of phase updated ---- ");
    })
  }

  onRisksOfSubphaseSelectedChanges(subphase, risks){
    subphase.Risks = risks;
  }

  onPhasetypeChange(phasetype){
    this.phase.Phasetype = phasetype;
  }

  doTextareaValueChange(ev, obj) {
    try {
      //console.info(obj);
      obj = ev.target.value;
      this.updatePhase();
    } catch(e) {
      console.info('could not set textarea-value');
    }
  }


}