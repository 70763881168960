import { Component, Injectable, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';

interface Company {
    name: string;
    id: number;
    info?: string;
    subcompany: boolean;
}

@Component({
    selector: 'navbar',
    template: require('./navbar.pug'),
    styles: [require('./navbar.css')]
})
@Injectable()
export class NavbarComponent implements OnInit{
    isCollapsed = true;
    isAdmin;
    isLoggedIn;
    currentUser = {};
    
    company: Company;
    companyName: string;
    companyId: number;
    expiringDays: string;

    static parameters = [HttpClient, AuthService, Router];
    constructor(private http: HttpClient, private authService: AuthService, private router: Router)  {
        this.http = http;
    }

    ngOnInit(){
        this.reset();
        this.authService.currentUserChanged.subscribe(user => {
            this.currentUser = user;
            this.reset();
            this.company = this.authService.getCurrentCompany();
        });
        this.authService.currentCompanyObservers.subscribe( (c: any) => {
            this.company = c;
        });
    }
    
    reset() {
        this.company = null;
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
            this.expiringDays = localStorage.getItem('expiringDays');
        });
        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
        this.authService.getCurrentUser().then(user => {
            this.currentUser = user;
        });
    }

    logout() {
        return this.authService.logout().then(() => {
            this.reset();
            //document.location.href = 'http://www1.pos-pimus.it/index.html';
            const url = '/login';
            this.router.navigate([url]);
        });
    }
    
}
    