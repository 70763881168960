import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange,  Output, EventEmitter,} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Phasetype {
    id: number;
    name: string;
    info?: string;
    Phases: Phase[];
    filteredPhases: Phase[];
}

interface Phase {
    phasetype: Phasetype;
    id: number;
    name: string;
    public: boolean;
    info?: string;
    selected: boolean;
}


@Component({
    selector: 'phases-as-checkboxes',
    template: require('./phases-as-checkboxes.pug'),
    styles: [require('./phases-as-checkboxes.css')],
})
export class PhasesAsCheckboxesComponent implements OnInit {

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    @Output() addedPhase = new EventEmitter<Phase>();
    @Output() removedPhase = new EventEmitter<Phase>();

    phasetypes: Phasetype[];
    filter: string = "";
    currentUserId = "";
    selectedPhases: Phase[];
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
        
        this.authService.getCurrentUser().then( u => {
            this.currentUserId = u._id;
        })
        this.selectedPhases = [];

    }
    
    ngOnInit() {
        this.reload();
    }
    
    reload() {
        console.log("PhasesAsCheckboxesComponent.ngOnInit");
        this.http.get('/api/phasetypes').subscribe( (list: Phasetype[]) => {
                this.phasetypes = list;
                this.assignCopy();
        });
    }
    
    assignCopy(){
       this.phasetypes.forEach( phasetype => {
           phasetype.filteredPhases = Object.assign([], phasetype.Phases);      
       })
    }
    
    filterPhases(){
       this.assignCopy();
       if(this.filter){
           this.phasetypes.forEach( phasetype => {
                phasetype.filteredPhases = phasetype.Phases.filter(
                    item => item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
                )
           })
       }
    }

    changePhase(phase) {
        phase.selected = !phase.selected;
        var added: Phase;
        var removed: Phase;
        
        if(phase.selected){
          this.selectedPhases.push(phase);
          added = phase;
        }else{
            this.phasetypes.forEach( phasetype => {
                this.selectedPhases = phasetype.filteredPhases.filter( d => {
                    return d.selected;
                });
            });
            removed = phase;
        }
        this.addedPhase.emit( added );
    }
    
}