import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Employeetype {
    id: number;
    name: string;
    description: string;
    id_user: number;
    public: boolean;
    deleted: boolean;
    Countermeasures: any[];
}


@Component({
    selector: 'employeetype-component',
    template: require('./employeetype.pug'),
    styles: [require('./employeetype.css')],
})

export class EmployeetypeComponent implements OnInit, OnDestroy {

    @Input() input: Employeetype; 

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    employeetype: Employeetype;
    newCountermeasure='';
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }
    

    ngOnInit() {    
    }
    
    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.input;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.employeetype = change.currentValue as Employeetype;
    }
    
    ngOnDestroy() {
    }

    updateEmployeetype(form) {
      if(form.invalid) return;
      this.submitted = true;
      return this.http.put('/api/employeetypes/'+this.employeetype.id, this.employeetype)
          .subscribe(employeetypeUpdated => {
              console.log('Updated Employeetype:', employeetypeUpdated);
          });
    }

    deleteEmployeetype(employeetype) {
        return this.http.delete(`/api/employeetypes/${employeetype._id}`)
            .subscribe(() => {
                console.log('Deleted Employeetype');
            });
    }
    
    addCountermeasure() {
        if(this.newCountermeasure) {
            let text = this.newCountermeasure;
            this.newCountermeasure = '';

            return this.http.post('/api/countermeasures', {id_employeetype: this.employeetype.id, name: text })
                .subscribe( countermeasure => {
                    console.log('Added Countermeasure:', countermeasure);
                    
                    this.employeetype.Countermeasures.push(countermeasure);
                });
        }
    }

}
