import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { ActivatedRoute, RouterModule, Routes } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap';

import { PhaseComponent } from "./phase.component";

import { DirectivesModule } from '../../../components/directives.module';


export const ROUTES: Routes = [
    { path: 'adminphase/:id', component: PhaseComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        DirectivesModule,
    ],
    declarations: [
        PhaseComponent,
    ],
    providers: [
    ],
    exports: [
        PhaseComponent,
    ],
})
export class PhaseModule {}
