import { Component } from '@angular/core';
import { AuthService } from '../../../components/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { NavbarComponent } from '../../../components/navbar/navbar.component';


// @flow
interface User {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface Billing {
    denominazione: string;
    nome_cognome: string;
    iva_cf: string;
    indirizzo: string;
    sdi: string;
    pec: string;
    discountToken: string;
}

@Component({
    selector: 'settings',
    template: require('./settings.pug'),
    styles: [require('./settings.css')],
})
export class SettingsComponent {
    user: User = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    billing: Billing = {
        denominazione: '',
        nome_cognome: '',
        iva_cf: '',
        indirizzo: '',
        sdi: '',
        pec: '',
        discountToken: '',
    };

    errors = {other: undefined};

    message = '';
    paymentMessage = null;

    submitted = false;
    AuthService;
    
    expiringDate;
    expiringDays;

    billingMessage = true;
    paying = false;

    passwordChangeMessage = "";
    passwordChangeErrorMessage = "";
    passwordChangeButtonDisabled = false;
    
    discountToken = "";
    discountValue = 0;
    discountNotValid = false;
    
    basePrice = 290;
    price = this.convert2DigitsAsNumber(this.basePrice);
    priceWithDiscount = this.convert2DigitsAsNumber(this.basePrice);
    
    vat = this.convert2Digits(this.price*0.22);
    vatWithDiscount = this.priceWithDiscount*0.22;
    
    static parameters = [HttpClient, AuthService, NavbarComponent];
    constructor(private http: HttpClient, _AuthService_: AuthService, private navbar: NavbarComponent) {
        this.AuthService = _AuthService_;
        this.expiringDate = localStorage.getItem('expiringDate');
        this.expiringDays = localStorage.getItem('expiringDays');
    }
    

    changePassword(form) {
        //if(form.invalid) return;
        //this.submitted = true;
        this.passwordChangeButtonDisabled = true;

        return this.AuthService.changePassword(this.user.oldPassword, this.user.newPassword)
            .then( () => {
                this.passwordChangeErrorMessage = 'La password è stata aggiornata.';
                this.passwordChangeErrorMessage = '';
            })
            .catch(() => {
                this.passwordChangeErrorMessage = 'ERRORE: la password attuale NON risulta corretta, la password NON è stata aggiornata.';
                this.passwordChangeMessage = '';
                this.passwordChangeButtonDisabled = false;
            });
    }
    
    validateBilling(){
        this.billingMessage = !this.isBillingValid();  
    }
    
    isBillingValid(){
        return (this.billing.denominazione!='' && this.billing.nome_cognome!='' && this.billing.iva_cf!='' && this.billing.indirizzo!='' && this.billing.sdi!='' && this.billing.pec!='');
    }
    
    checkDiscount(){
        return this.http.get('/api/discounts/'+ this.discountToken+'/value').subscribe( (discount: any) => {
            if(discount && discount.value > 0){
                this.discountValue = this.convert2DigitsAsNumber(discount.value);
                this.priceWithDiscount = this.convert2DigitsAsNumber(this.price - this.discountValue);
                this.vatWithDiscount = this.convert2DigitsAsNumber(this.priceWithDiscount * 0.22);
                this.billing.discountToken = this.discountToken; 
                this.discountNotValid = false;
            }
            else{
                this.discountValue = 0;
                this.priceWithDiscount = this.convert2DigitsAsNumber(this.basePrice - this.discountValue);
                this.vatWithDiscount = this.convert2DigitsAsNumber(this.basePrice * 0.22);
                this.discountNotValid = true;
            }
        })
    }

    convert2Digits(v){
        return (Math.round(v * 100) / 100).toFixed(2);
    }

    convert2DigitsAsNumber(v){
        return (Math.round(v * 100) / 100);
    }


    pay(){
        if(!this.isBillingValid()){
            alert("Dati per la fatturazione elettronica non completi o non validi, non è possibile procedere con il pagamento!");
            this.billingMessage = true;
            this.paying = false;
            return;
        }
        this.paying = true;
        console.log((this.priceWithDiscount*100).toFixed(0));
        const payment = {
            amount: (this.priceWithDiscount*1.22*100).toFixed(0),
            months: 12,
            customerEmail: this.AuthService.currentUser.email,
            customerName: this.AuthService.currentUser.name,
            billingData: this.billing,
            discountToken: this.discountToken,
        };
        return this.http.post('/api/payments', payment).subscribe( (paymentSaved: any) => {
            console.log('Payment started:', paymentSaved);

            var stripe = (<any>window).Stripe('pk_live_LGK079FIsa0UKTRJ4HSevIol00mqTJjT3o');
            //var stripe = (<any>window).Stripe('pk_test_7ymjTsFB89QcNZToSIxWmrFV00U3z6MsEV');
            stripe.redirectToCheckout({
                sessionId: paymentSaved.id
            }).then( result => {
                this.message = result.error.message;
            });

        }, error => {
            this.paymentMessage = error.message;
        });

    }
    
    
}
