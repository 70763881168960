import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../components/socket/socket.service';

interface Sector {
    name: string;
    public: boolean;
    info?: string;
}


@Component({
    selector: 'sectors',
    template: require('./sectors.pug'),
    styles: [require('./sectors.css')],
})

export class SectorsComponent implements OnInit, OnDestroy {
    SocketService;
    sectors: Sector[] = [];
    
    selectedSector: Sector;
    newSector = '';

    static parameters = [HttpClient, SocketService];
    constructor(private http: HttpClient, private socketService: SocketService) {
        this.http = http;
        this.SocketService = socketService;
    }

    ngOnInit() {    
        this.http.get('/api/sectors')
            .subscribe((sectors: Sector[]) => {
                this.sectors = sectors;
                this.SocketService.syncUpdates('sector', this.sectors);
            });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('sector');        
    }

    addSector() {
        if(this.newSector) {
            let text = this.newSector;
            this.newSector = '';

            return this.http.post('/api/sectors', { name: text })
                .subscribe(sector => {
                    console.log('Added Sector:', sector);
                    
                    this.sectors.push(sector as Sector);
                    this.selectedSector = sector as Sector;
                });
        }
    }

    deleteSector(sector) {
        return this.http.delete(`/api/sectors/${sector._id}`)
            .subscribe(() => {
                console.log('Deleted Sector');
            });
    }
}
