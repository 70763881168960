import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';

interface Pimus {
    id: number;
    id_company: number;
    name: string;
    public: boolean;
    info?: string;
    error: boolean;
    downloading: boolean;
    deleted: boolean;

}

interface Company {
    id: number;
    name: string;
    email: string;
}

@Component({
    selector: 'pimusList',
    template: require('./pimusList.pug'),
    styles: [require('./pimusList.css')],
})
export class PimusListComponent implements OnInit, OnDestroy {
    pimusList: Pimus[] = [];
    pimusListOfCompany: Pimus[] = [];
    deletedPimusListOfCompany: Pimus[] = [];
    
    showDeleted = false;
    
    company: Company;
    
    email1: string = '';
    email2: string = '';
    email3: string = '';
    email4: string = '';
    email5: string = '';
    sentToAll: boolean = false;
    
    static parameters = [HttpClient, Router, AuthService];
    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {
        this.http = http;
        this.router = router;
        this.authService = authService;
    }

    ngOnInit() {    
        this.http.get('/api/pimus').subscribe( (pimusList: Pimus[]) => {
            this.pimusList = pimusList;
            this.setCompany( this.authService.getCurrentCompany() );
            this.authService.currentCompanyObservers.subscribe( (company: any) => {
                    this.setCompany( company );
            });        
        });
    }

    ngOnDestroy() {
    }

    setCompany(company){
        this.company = company;
        this.email1 = this.company.email;
        this.pimusListOfCompany = this.pimusList.filter(o => {
            return (o.id_company == company.id && !o.deleted) ;
        })    
        this.deletedPimusListOfCompany = this.pimusList.filter(o => {
            return (o.id_company == company.id);
        })    
    }
    
    addPimus() {
        var r = confirm("Stai per inserire una nuovo PiMUS. Premi OK per procedere"); 
        if (r == true) {
            return this.http.post('/api/pimus', { id_company: this.company.id }).subscribe( (pimus: Pimus) => {
                    console.log('Added Pimus:'+ pimus.id);
                    const url = '/pimus/'+pimus.id;
                    this.router.navigate([url]);
            });
        }
    }

    createPdf(pimus) {
        pimus.downloading = true;
        pimus.error = false;
        this.http.get('/api/pimus/'+pimus.id+'/pdf', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "pimus_"+pimus.id+".pdf";
            link.click();
            pimus.downloading = false;
            pimus.error = false;
        }, error => {
            pimus.downloading = false;
            pimus.error = true;
        });
    }

    sendEmail(pimus) {
        var obj = {
            id_pimus: pimus.id,
            sentToAll: this.sentToAll,
            emails: [this.email1, this.email2, this.email3, this.email4, this.email5],
        }
        this.http.post('/api/pimus/email', obj).subscribe( data => {
            console.log("email sent");
        })
    }
    
}
