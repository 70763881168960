import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';


interface Company {
    id: number;
    name: string;
    documents: Document[];
    documentTypesNotCovered: string[];
    Employees: Employee[];
}

interface Document {
    id: number;
    filename: string;
    type: string;
}

interface Securitytask {
    id: number;
    name: string;
}

interface Employee {
    documents: Document[];
    documentTypesNotCovered: string[];
    Securitytasks: Securitytask[];
}


@Component({
    selector: 'documents',
    template: require('./documents.pug'),
    styles: [require('./documents.css')],
})
export class DocumentsComponent implements OnInit, OnDestroy {
    
    company: Company;
    showDeleted: false;

    documentTypesForCompany = [
        'DURC',
        'VISURA CAMERALE',       
    ];

    documentTypesBySecuritytask = [
        { securitytask: "Capo Cantiere", documentTypes: ['CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Direttore tecnico di Cantiere", documentTypes: ['CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Preposto", documentTypes: ['ATTESTATO PREPOSTO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Patentino Ponteggio", documentTypes: ['PATENTINO PONTEGGIO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Addetto Pronto Soccorso", documentTypes: ['CORSO PRIMO SOCCORSO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Addetto antincendio", documentTypes: ['CORSO ANTI INCENDIO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "Preposto Ponteggio", documentTypes: ['PATENTINO PONTEGGIO','CONSEGNA D.P.I.',"IDONEITA' MANSIONE"]},
        { securitytask: "R.L.S.", documentTypes: ['FORMAZIONE R.L.S.','NOMINA R.L.S.']},
        { securitytask: "R.S.P.P.", documentTypes: ['ATTESTATO R.S.P.P.','NOMINA R.S.P.P.']},
        { securitytask: "Medico Competente", documentTypes: ['NOMINA MEDICO COMPETENTE']},
        { securitytask: "allEmployees", documentTypes: ['CONSEGNA D.P.I.',"IDONEITA' MANSIONE",'ATTESTATO DI FORMAZIONE']},
    ]; 

    static parameters = [HttpClient, Router, AuthService];
    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {
        this.http = http;
        this.authService = authService;
    }

    ngOnInit() {    
        this.setCompany( this.authService.getCurrentCompany() );
        this.authService.currentCompanyObservers.subscribe( (c: any) => {
            this.setCompany( c );
        });        
    }

    ngOnDestroy() {
    }

    setCompany(c){
        this.http.get('/api/companies/'+c.id+'/documents').subscribe( (loaded: Company) => {
            this.company = loaded;
            this.updateDocumentTypesNotCovered();
        });
    }


    downloadDocument(doc) {
        doc.downloading = true;
        doc.error = false;
        this.http.get('/api/documents/'+doc.id+'/download', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "document_"+doc.id+".pdf";
            link.click();
            doc.downloading = false;
            doc.error = false;
        }, error => {
            doc.downloading = false;
            doc.error = true;
        });
    }
    
    addCompanyDocument(company){
        var r = confirm("Stai per inserire una nuovo DOCUMENTO. Premi OK per procedere"); 
        if (r == true) {
            return this.http.post('/api/documents', { type:'DA DEFINIRE', filename: 'NUOVO FILE', data_documento: '2020-01-01', id_company: company.id}).subscribe( (d: Document) => {
                    console.log('Added Document:', d);
                    const url = '/document/'+d.id;
                    this.router.navigate([url]);
            });
        }
    }
    
    addEmployeeDocument(employee){
        var r = confirm("Stai per inserire una nuovo DOCUMENTO. Premi OK per procedere"); 
        if (r == true) {
            return this.http.post('/api/documents', { type:'DA DEFINIRE', filename: 'NUOVO FILE', data_documento: '2020-01-01', id_employee: employee.id}).subscribe( (d: Document) => {
                    console.log('Added Document:', d);
                    const url = '/document/'+d.id;
                    this.router.navigate([url]);
            });
        }
    }

    addWorkingtoolDocument(workingtool){
        var r = confirm("Stai per inserire una nuovo DOCUMENTO. Premi OK per procedere"); 
        if (r == true) {
            return this.http.post('/api/documents', { type:'LIBRETTO', filename: 'NUOVO FILE', data_documento: '2020-01-01', id_workingtool: workingtool.id}).subscribe( (d: Document) => {
                    console.log('Added Document:', d);
                    const url = '/document/'+d.id;
                    this.router.navigate([url]);
            });
        }
    }    

    scroll(id) {
        let el = document.getElementById(id);
        el.scrollIntoView();
    }

    updateDocumentTypesNotCovered(){
        var documentTypesPresent = [];
        this.company.documents.forEach(d => {
            documentTypesPresent.push(d.type);
        });
        this.company.documentTypesNotCovered = [];
        this.documentTypesForCompany.forEach(a => {
            if( documentTypesPresent.indexOf(a) < 0 ){
                this.company.documentTypesNotCovered.push( a );
            }
        });

        this.company.Employees.forEach(employee => {
            var documentTypesPresent = [];
            employee.documents.forEach(d => {
                documentTypesPresent.push(d.type);
            });
            employee.documentTypesNotCovered = [];
            employee.Securitytasks.forEach( st => {
                this.documentTypesBySecuritytask.forEach( dt => {
                    if(dt.securitytask == st.name){
                        dt.documentTypes.forEach(a => {
                            if( documentTypesPresent.indexOf(a) < 0 && employee.documentTypesNotCovered.indexOf(a) < 0 ){
                                employee.documentTypesNotCovered.push( a );
                            }
                        });
                    }
                })
            });
        })

    }
}
