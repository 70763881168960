import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';


interface Company {
    id: number;
    name: string;
}

interface Workingtool {
    id: number;
    filename: string;
    type: string;
}


@Component({
    selector: 'workingtools',
    template: require('./workingtools.pug'),
    styles: [require('./workingtools.css')],
})
export class WorkingtoolsComponent implements OnInit, OnDestroy {
    
    company: Company;
    showDeleted = false;

    
    static parameters = [HttpClient, Router, AuthService];
    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {
        this.http = http;
        this.authService = authService;
    }

    ngOnInit() {    
        this.setCompany( this.authService.getCurrentCompany() );
        this.authService.currentCompanyObservers.subscribe( (c: any) => {
            this.setCompany( c );
        });        
    }

    ngOnDestroy() {
    }

    setCompany(c){
        this.http.get('/api/companies/'+c.id+'/workingtools').subscribe( (loaded: Company) => {
            this.company = loaded;
        });
    }


    downloadWorkingtool(workingtool) {
        workingtool.downloading = true;
        workingtool.error = false;
        this.http.get('/api/workingtools/'+workingtool.id+'/download', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "workingtool_"+workingtool.id+".pdf";
            link.click();
            workingtool.downloading = false;
            workingtool.error = false;
        }, error => {
            workingtool.downloading = false;
            workingtool.error = true;
        });
    }
    
    addWorkingtool(company, workingtooltype){
        var r = confirm("Stai per inserire una attrezzatura o macchinario di tipo "+workingtooltype.name+". Premi OK per procedere"); 
        if (r == true) {
            return this.http.post('/api/workingtools', { brand:'Marca da definire', model: 'Modello da definire', id_company: company.id, id_workingtooltype: workingtooltype.id}).subscribe( (d: Workingtool) => {
                    console.log('Added Workingtool:', d);
                    const url = '/workingtool/'+d.id;
                    this.router.navigate([url]);
            });
        }
    }
    
}
