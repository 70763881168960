import './app.css';

import 'reflect-metadata';
import 'zone.js/dist/zone';
//import './polyfills';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// depending on the env mode, enable prod mode or add debugging modules
if(process.env.NODE_ENV === 'production') {
    enableProdMode();
}

import { AppModule } from './app.module';

export function main() {
    return platformBrowserDynamic().bootstrapModule(AppModule).then(function(MODULE_REF) {   if (module["hot"]) {     module["hot"]["accept"]();          if (MODULE_REF.instance["hmrOnInit"]) {       module["hot"]["data"] && MODULE_REF.instance["hmrOnInit"](module["hot"]["data"]);     }     if (MODULE_REF.instance["hmrOnStatus"]) {       module["hot"]["apply"](function(status) {         MODULE_REF.instance["hmrOnStatus"](status);       });     }     if (MODULE_REF.instance["hmrOnCheck"]) {       module["hot"]["check"](function(err, outdatedModules) {         MODULE_REF.instance["hmrOnCheck"](err, outdatedModules);       });     }     if (MODULE_REF.instance["hmrOnDecline"]) {       module["hot"]["decline"](function(dependencies) {         MODULE_REF.instance["hmrOnDecline"](dependencies);       });     }     module["hot"]["dispose"](function(store) {       MODULE_REF.instance["hmrOnDestroy"] && MODULE_REF.instance["hmrOnDestroy"](store);       MODULE_REF.destroy();       MODULE_REF.instance["hmrAfterDestroy"] && MODULE_REF.instance["hmrAfterDestroy"](store);     });   }   return MODULE_REF; });
}

if(document.readyState === 'complete') {
    main();
} else {
    document.addEventListener('DOMContentLoaded', main);
}
