import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';
import {IMyDpOptions, IMyDateModel} from 'mydatepicker';
import * as $ from 'jquery';


/*
+---------------+-------------+------+-----+---------+----------------+
| Field         | Type        | Null | Key | Default | Extra          |
+---------------+-------------+------+-----+---------+----------------+
| id            | int(11)     | NO   | PRI | NULL    | auto_increment |
| id_user       | int(11)     | YES  | MUL | NULL    |                |
| id_company    | int(11)     | YES  | MUL | NULL    |                |
| id_employee   | int(11)     | YES  | MUL | NULL    |                |
| type          | varchar(60) | YES  |     | NULL    |                |
| filename      | varchar(60) | YES  |     | NULL    |                |
| path          | varchar(60) | YES  |     | NULL    |                |
| document_date | date        | YES  |     | NULL    |                |
| deleted       | char(1)     | YES  |     | 0       |                |
+---------------+-------------+------+-----+---------+----------------+

+--------------------------+
| type                     |
+--------------------------+
| ALTRI DOCUMENTI          |
| ASSUNZIONE               |
| ATTESTATO DI FORMAZIONE  |
| ATTESTATO FORMAZIONE DPI |
| ATTESTATO PREPOSTO       |
| ATTESTATO R.S.P.P.       |
| CAMERALE                 |
| COMUNICAZIONE DISTACCO   |
| CONSEGNA DPI             |
| CORSO ANTI INCENDIO      |
| CORSO APP. SOLLEVAMENTO  |
| CORSO PRIMO SOCCORSO     |
| DA DEFINIRE              |
| DOCUMENTO DI IDENTITA'   |
| DURC                     |
| FORMAZIONE RLS           |
| IDONEITA' MANSIONE       |
| MANUALISTICA             |
| NOMINA MEDICO COMPETENTE |
| NOMINA R.S.P.P.          |
| NOMINA RLS               |
| PATENTINO PONTEGGIO      |
| PERMESSO DI SOGGIORNO    |
| TESSERINO                |
| UNIFICATO LAV DISTACCO   |
| VISURA CAMERALE          |
+--------------------------+
*/

interface Document {
    id: number;
    id_user: number;
    id_company: number;
    id_employee: number;
    type: string;
    filename: string;
    path: string;
    document_date: Date;
    document_date_js: any;
    deleted: boolean;
    errors: string[];
    valid: boolean;
}

interface Header {
    name: string;
    value: string;
}

const allowedFileType = ["jpg", "png", "pdf"];

@Component({
    selector: 'Document',
    template: require('./document.pug'),
    styles: [require('./document.css')],
})
export class DocumentComponent implements OnInit, AfterViewInit {
    public dateOptions: IMyDpOptions = {
        dateFormat: 'dd-mm-yyyy',
        dayLabels:	{su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Gio', fr: 'Ven', sa: 'Sab'},
        monthLabels:	{ 1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug', 8: 'Ago', 9: 'Set', 10: 'Ott', 11: 'Nov', 12: 'Dic' },
        todayBtnTxt: "Oggi",
        maxYear: 2030
    };

    types=[
        'ALTRI DOCUMENTI',        
        'ASSUNZIONE',
        'ATTESTATO DI FORMAZIONE',
        'ATTESTATO DI FORMAZIONE GENERALE',
        'ATTESTATO FORMAZIONE D.P.I.',
        'ATTESTATO GRU A TORRE',
        'ATTESTATO LAVORI IN QUOTA',
        'ATTESTATO LAVORI IN FUNE',
        'ATTESTATO PIATTAFORMA AEREA',
        'ATTESTATO PREPOSTO',
        'ATTESTATO R.S.P.P.',
        'ATTESTATO USO DPI DI TERZA CATEGORIA',
        'COMUNICAZIONE DISTACCO',
        'CONSEGNA D.P.I.',
        'CORSO ANTI INCENDIO',    
        'CORSO APP. SOLLEVAMENTO',
        'CORSO PRIMO SOCCORSO',
        'DA DEFINIRE',            
        "DOCUMENTO DI IDENTITA'",
        'DURC',
        'D.V.R.',
        'FORMAZIONE R.L.S.',
        "IDONEITA' MANSIONE",
        'LIBRETTO',
        'MANUALISTICA',
        'NOMINA MEDICO COMPETENTE',
        'NOMINA R.S.P.P.',        
        'NOMINA R.L.S.',
        'NOMINA PREPOSTO',
        'PATENTINO PONTEGGIO',    
        'PERMESSO DI SOGGIORNO',
        'TESSERINO',
        'SCHEDA DI LAVORAZIONE',  
        'UNIFICATO LAV DISTACCO',
        'UNI LAV',
        'VISURA CAMERALE',       
    ]
 
    doc: Document;
    
    uploader: FileUploader = new FileUploader({url: '/api/documents/0/upload'});

    previewURL: string ='';
    
    hasDropZoneOver: boolean;
    
    response: string;

    errors: {field?: Error} = {};
    submitted = false;
    expiringDays = '';
    
    static parameters = [HttpClient, Router, ActivatedRoute, ElementRef];
    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private el: ElementRef) {
        this.http = http;
        this.router = router;

        this.uploader.onAfterAddingFile = (fileItem) => {
            document.getElementById("openModalLoading").click();
            fileItem.withCredentials = false;
            const img = document.getElementById('preview');
            img['src'] = '/assets/images/clock.png';
	    $("#fileInput").value = '';
	    //document.getElementById("fileInput").value = '';
        };

        this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
            console.log("ImageUpload:uploaded:", item, status, response);
            if(item.file.name){
                this.doc.filename = item.file.name; 
                this.downloadPreview( this.doc );
                this.updateDocument();
            }
        };
    }

    ngOnInit() {    
        this.expiringDays = localStorage.getItem('expiringDays');
        if( parseInt( this.expiringDays) <0 ){
          alert("Documento non modificabile, account scaduto da "+ Math.abs(parseInt(this.expiringDays)) + " giorni.");
        }

    }

    ngAfterViewInit() {
        return this.route.paramMap.subscribe( params => {
            var id: string = params.get('id');
            return this.http.get('/api/documents/'+id).subscribe( (d: Document) => {
                this.doc = d;
                this.setDate();
                var token = localStorage.getItem('id_token');
                this.uploader.setOptions({ url: '/api/documents/'+this.doc.id+'/upload', autoUpload: true, headers: [{name: 'Authorization', value: 'Bearer ' + token}], allowedMimeType: ['application/pdf', 'image/jpeg', 'image/png'] });
                this.downloadPreview( this.doc );
            });
        });
    }

    setDate(){
        if(!this.doc.document_date){
            this.doc.document_date = new Date();
        }else{
            this.doc.document_date = new Date(this.doc.document_date)
        }
        this.doc.document_date_js = { 'date': { year: this.doc.document_date.getFullYear(), 'month': this.doc.document_date.getMonth() + 1, 'day': this.doc.document_date.getDate()} };
    }

    onDocumentDateChanged(event: IMyDateModel) {
        this.doc.document_date = event.jsdate ;
        this.doc.document_date.setDate( this.doc.document_date.getDate() + 1);
        this.updateDocument();
    }
    public fileOver(e:any):void {
        this.hasDropZoneOver = e;
    }

    
    updateDocument() {
        this.http.put('/api/documents/'+this.doc.id, this.doc).subscribe( (d: Document) => {
            this.doc.errors = d.errors;
            this.doc.valid = d.valid;
            console.log('Updated Document!');
        });
    }

    
    downloadDocument(doc) {
        doc.downloading = true;
        doc.error = false;
        this.http.get('/api/documents/'+doc.id+'/download', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "document_"+doc.id+".pdf";
            link.click();
            doc.downloading = false;
            doc.error = false;
        }, error => {
            doc.downloading = false;
            doc.error = true;
        });
    }

    downloadPreview(doc) {
        this.http.get('/api/documents/'+doc.id+'/preview.png', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'image/png'});
            const img = document.getElementById('preview');
            img['src'] = window.URL.createObjectURL(data);
            document.getElementById("closeModalLoading").click();
        }, error => {
            console.log(error);
        });
    }

    completed(){
        const url = '/documents';
        this.router.navigate([url]);
    }


    delete(){
        var r = confirm("Stai per cancellare un documento. Premi OK per procedere"); 
        if (r == true) {
            this.http.delete('/api/documents/'+ this.doc.id).subscribe( () => {
                this.completed();
            });
        } 
    }
}
