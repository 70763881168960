import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { ActivatedRoute, RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { PimusPageComponent } from './pimus.component';
import { DirectivesModule } from '../../components/directives.module';
import { MyDatePickerModule } from 'mydatepicker';

export const ROUTES: Routes = [
    { path: 'pimus/:id', component: PimusPageComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        DirectivesModule,
        MyDatePickerModule,
    ],
    declarations: [
        PimusPageComponent,
    ],
    providers: [
    ],
    exports: [
        PimusPageComponent,
    ],
})
export class PimusPageModule {}
