import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Phase {
    id: number;
    name: string;
    description: string;
    isEditing: boolean;
    Subphases: any[];
    Employeetypes: any[];
  }
  
  interface Subphase {
    id: number;
    name: string;
    description: string;
    isEditing: boolean;
    Risks: any[];
  }

@Component({
    selector: 'phase-edit',
    template: require('./phase-edit.pug'),
    styles: [require('./phase-edit.css')],
})

export class PhaseEditComponent implements OnInit, OnDestroy {

    @Input() input: Phase; 

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    phase: Phase;
    newCountermeasure='';
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    ngOnInit() {    
    }
    
    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.input;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.phase = change.currentValue as Phase;
      console.log("editing phase RECEIVED")
      console.log(this.phase)
    }
    
    ngOnDestroy() {
    }
      
    savePhase(){
      this.http.put('/api/phases/'+this.phase.id, this.phase).subscribe( () => {
        console.log('Updated phase');
      });  
    }

    doTextareaValueChange(ev, obj) {
      try {
        //console.info(obj);
        obj = ev.target.value;
        //console.info(obj);
      } catch(e) {
        console.info('could not set textarea-value');
      }
    }


    onEmployeetypesOfPhaseSelectedChanges(employeetypes){
      this.phase.Employeetypes = employeetypes;
      this.http.put('/api/phases/'+this.phase.id+'/employeetypes', this.phase ).subscribe( (phaseUpdated: any)  => {
          console.log(" ---- employees of phase updated ---- ");
      })
  }
}
