// @flow
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../components/auth/auth.service';
import {Router, ActivatedRoute, Params} from '@angular/router';

interface User {
    token: string;
    newPassword: string;
    confirmPassword: string;
}

@Component({
    selector: 'passwordReset',
    template: require('./passwordReset.pug'),
})
export class PasswordResetComponent {

    user: User = {
        token: '',
        newPassword: '',
        confirmPassword: ''
    };
    
    message: string;
    errorMessage: string;
    buttonDisabled:boolean = false;


    static parameters = [HttpClient, AuthService, Router, ActivatedRoute];
    constructor(private http: HttpClient, private authService: AuthService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe( params => {
            this.user.token = params.token;
        });
    }

    changePassword(form) {
        if( this.user.newPassword != this.user.confirmPassword){
            this.errorMessage = 'ERRORE: la "Conferma della nuova Password" non corrisponde con la "Nuova Password."';
            return;
        }
        this.buttonDisabled = true;

        this.http.post('/api/users/resetpassword', this.user,{ observe: 'response' }).toPromise()
            .then(data => {
                console.log('Password impostata');
                this.message = "La nuova password è stata impostata, riceverai una email di conferma.";
            })
            .catch(err => {
                console.log('Password NON impostata');
                this.errorMessage = "ERRORE: la nuova password NON è stata impostata, richiesta non valida.";
            });
        
    }

}
