import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'main',
    template: require('./main.pug'),
    //styles: [require('./main.css')],
})
export class MainComponent implements OnInit {

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
    }

    ngOnInit() {    
    }

}
