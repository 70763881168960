import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Phase {
    id: number;
    name: string;
    description: string;
    isEditing: boolean;
    Subphases: any[];
  }
  
  interface Subphase {
    id: number;
    name: string;
    description: string;
    isEditing: boolean;
    Risks: any[];
  }
@Component({
    selector: 'subphase-edit',
    template: require('./subphase-edit.pug'),
    styles: [require('./subphase-edit.css')],
})

export class SubphaseEditComponent implements OnInit, OnDestroy {

    @Input() input: Subphase; 

    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    subphase: Subphase;
    newCountermeasure='';
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }
    

    ngOnInit() {    
    }
    
    ngOnChanges(changes: SimpleChanges) {
      const change: SimpleChange = changes.input;
      if(change.previousValue == change.currentValue){
          return;
      }
      this.subphase = change.currentValue as Subphase;
      console.log("editing subphase RECEIVED")
      console.log(this.subphase)
    }
    
    ngOnDestroy() {
    }
  
    saveSubphase(){
      this.http.put('/api/subphases/'+this.subphase.id, this.subphase).subscribe( (subphaseUpdated: any) => {
          console.log('Updated Subphase:', subphaseUpdated);
      });  
    }
    
    doTextareaValueChange(ev, obj) {
      try {
        //console.info(obj);
        obj = ev.target.value;
        //console.info(obj);
      } catch(e) {
        console.info('could not set textarea-value');
      }
    }

    onRisksOfSubphaseSelectedChanges(risks){
      console.log(risks)
      this.subphase.Risks = risks;
      this.http.put('/api/subphases/'+this.subphase.id+'/risks', this.subphase ).subscribe( (phaseUpdated: any)  => {
        console.log(" ---- risks of subphase updated ---- ");
      })
    }

}
