import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface Dpi {
    name: string;
    public: boolean;
    info?: string;
}


@Component({
    selector: 'dpis',
    template: require('./dpis.pug'),
    styles: [require('./dpis.css')],
})

export class DpisComponent implements OnInit, OnDestroy {
    dpis: Dpi[] = [];
    
    selectedDpi: Dpi;
    newDpi = '';

    static parameters = [HttpClient];
    constructor(private http: HttpClient) {
        this.http = http;
    }

    ngOnInit() {    
        this.http.get('/api/dpis')
            .subscribe((dpis: Dpi[]) => {
                this.dpis = dpis;
            });
    }

    ngOnDestroy() {
    }

    addDpi() {
        if(this.newDpi) {
            let text = this.newDpi;
            this.newDpi = '';

            return this.http.post('/api/dpis', { name: text })
                .subscribe(dpi => {
                    console.log('Added Dpi:', dpi);
                    
                    this.dpis.push(dpi as Dpi);
                    this.selectedDpi = dpi as Dpi;
                });
        }
    }

    deleteDpi(dpi) {
        return this.http.delete(`/api/dpis/${dpi._id}`)
            .subscribe(() => {
                console.log('Deleted Dpi');
            });
    }
}
