import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { PaymentComponent } from './payment.component';
import { DirectivesModule } from '../../components/directives.module';

export const ROUTES: Routes = [
    { path: 'payment/:result', component: PaymentComponent },
];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        DirectivesModule,
    ],
    declarations: [
        PaymentComponent,
    ],
    providers: [
    ],
    exports: [
        PaymentComponent,
    ],
})
export class PaymentModule {}
