import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';

interface Pos {
    id: number;
    id_company: number;
    name: string;
    public: boolean;
    info?: string;
    error: boolean;
    downloading: boolean;
    deleted: boolean;
}

interface Company {
    id: number;
    name: string;
    email: string;
}

@Component({
    selector: 'posList',
    template: require('./posList.pug'),
    styles: [require('./posList.css')],
})
export class PosListComponent implements OnInit, OnDestroy {
    posList: Pos[] = [];
    posListOfCompany: Pos[] = [];
    deletedPosListOfCompany: Pos[] = [];
    company: Company;
    showDeleted = false;

    email1: string = '';
    email2: string = '';
    email3: string = '';
    email4: string = '';
    email5: string = '';
    sentToAll: boolean = false;
    
    static parameters = [HttpClient, Router, AuthService];
    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {
        this.http = http;
        this.router = router;
        this.authService = authService;
    }

    ngOnInit() {    
        this.http.get('/api/pos').subscribe( (posList: Pos[]) => {
            this.posList = posList;
            this.setCompany( this.authService.getCurrentCompany() );
            this.authService.currentCompanyObservers.subscribe( (company: any) => {
                    this.setCompany( company );
            });        
        });
    }

    ngOnDestroy() {
    }

    setCompany(company){
        this.company = company;
        this.email1 = this.company.email;
        this.posListOfCompany = this.posList.filter(o => {
            return (o.id_company == company.id && !o.deleted);
        })    
        this.deletedPosListOfCompany = this.posList.filter(o => {
            return (o.id_company == company.id );
        })    
    }
    
    addPos() {
        var r = confirm("Stai per inserire una nuovo POS. Premi OK per procedere"); 
        if (r == true) {
            return this.http.post('/api/pos', { id_company: this.company.id }).subscribe( (pos: Pos) => {
                    console.log('Added Pos:'+ pos.id);
                    const url = '/pos/'+pos.id;
                    this.router.navigate([url]);
            });
        }
    }

    createPdf(pos) {
        pos.downloading = true;
        pos.error = false;
        this.http.get('/api/pos/'+pos.id+'/pdf', {responseType: 'blob'} ).subscribe( data => {
            var blob = new Blob([data], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "pos_"+pos.id+".pdf";
            link.click();
            pos.downloading = false;
            pos.error = false;
        }, error => {
            pos.downloading = false;
            pos.error = true;
        });
    }

    sendEmail(pos) {
        var obj = {
            id_pos: pos.id,
            sentToAll: this.sentToAll,
            emails: [this.email1,this.email2,this.email3,this.email4,this.email5],
        }
        this.http.post('/api/pos/email', obj).subscribe( data => {
            console.log("email sent");
        })
    }
    
}
