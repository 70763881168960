import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../components/socket/socket.service';

interface Subphase {
    name: string;
    public: boolean;
    info?: string;
}


@Component({
    selector: 'subphases',
    template: require('./subphases.pug'),
    styles: [require('./subphases.css')],
})

export class SubphasesComponent implements OnInit, OnDestroy {
    SocketService;
    subphases: Subphase[] = [];
    
    selectedSubphase: Subphase;
    newSubphase = '';

    static parameters = [HttpClient, SocketService];
    constructor(private http: HttpClient, private socketService: SocketService) {
        this.http = http;
        this.SocketService = socketService;
    }

    ngOnInit() {    
        this.http.get('/api/subphases')
            .subscribe((subphases: Subphase[]) => {
                this.subphases = subphases;
                this.SocketService.syncUpdates('subphase', this.subphases);
            });
    }

    ngOnDestroy() {
        this.SocketService.unsyncUpdates('subphase');        
    }

    addSubphase() {
        if(this.newSubphase) {
            let text = this.newSubphase;
            this.newSubphase = '';

            return this.http.post('/api/subphases', { name: text })
                .subscribe(subphase => {
                    console.log('Added Subphase:', subphase);
                    
                    this.subphases.push(subphase as Subphase);
                    this.selectedSubphase = subphase as Subphase;
                });
        }
    }

    deleteSubphase(subphase) {
        return this.http.delete(`/api/subphases/${subphase._id}`)
            .subscribe(() => {
                console.log('Deleted Subphase');
            });
    }
}
