import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { ActivatedRoute, RouterModule, Routes } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap';

import { RiskComponent } from "./risk.component";

import { DirectivesModule } from '../../../components/directives.module';


export const ROUTES: Routes = [
    { path: 'adminrisk/:id', component: RiskComponent },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        DirectivesModule,
    ],
    declarations: [
        RiskComponent,
    ],
    providers: [
    ],
    exports: [
        RiskComponent,
    ],
})
export class RiskModule {}
