import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from   '../../components/auth/auth.service';

interface Countermeasure {
    name: string;
    public: boolean;
    info?: string;
    Risks: Risk[];
}

interface Risk {
    name: string;
    info?: string;
    Countermeasures: Countermeasure[];
}


@Component({
    selector: 'countermeasures',
    template: require('./countermeasures.pug'),
    styles: [require('./countermeasures.css')],
})

export class CountermeasuresPageComponent implements OnInit, OnDestroy {
    risks: Risk[] = [];
    
    selectedCountermeasure: Countermeasure;
    
    isAdmin;
    isLoggedIn;
    
    static parameters = [HttpClient, AuthService];
    constructor(private http: HttpClient, private authService: AuthService,) {
        this.http = http;
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });        
    }

    ngOnInit() {    
        this.http.get('/api/risks')
            .subscribe((risks: Risk[]) => {
                this.risks = risks;
            });
    }

    ngOnDestroy() {
    }

    addCountermeasure(countermeasureName, risk) {
        if(countermeasureName) {
            
            return this.http.post('/api/countermeasures', { name: countermeasureName, Risks: [risk]  })
                .subscribe( (countermeasure: Countermeasure) => {
                    console.log('Added Countermeasure:', countermeasure);

                    risk.Countermeasures.push(countermeasure);
                    


                    this.selectedCountermeasure = countermeasure;
                    countermeasureName = '';

                });
        }
    }

    deleteCountermeasure(countermeasure) {
        return this.http.delete(`/api/countermeasures/${countermeasure._id}`)
            .subscribe(() => {
                console.log('Deleted Countermeasure');
            });
    }
}
