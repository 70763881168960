import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../components/auth/auth.service';


@Component({
    selector: 'payment',
    template: require('./payment.pug'),
    styles: [require('./payment.css')],
})
export class PaymentComponent implements OnInit{
    
    message = "";

    static parameters = [HttpClient, Router, AuthService, ActivatedRoute];
    constructor(private http: HttpClient, private router: Router, private authService: AuthService, private route: ActivatedRoute,) {
        this.http = http;
        this.authService = authService;
    }

    ngOnInit() {    
        this.route.paramMap.subscribe( params => {
            var paymentResult: string = params.get('result');
            if(paymentResult == 'success'){
                this.message = "Il pagamento è stato effettuato con successo. Il periodo di validità del suo account è stato esteso.";
            } else {
                this.message = "Il pagamento è stato annullato. Il periodo di validità del suo account non è stato esteso.";
            }
        });
        
    }

  
}
