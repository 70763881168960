import { Component } from '@angular/core';

@Component({
    selector: 'admin',
    template: require('./admin.pug'),
    styles: [require('./admin.css')],
})
export class AdminComponent {

    constructor() {
    }

}
