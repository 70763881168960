import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AuthService } from '../auth/auth.service';

interface Sector {
    id: number;
    name: string;
    public: boolean;
    deleted: boolean;
}

@Component({
    selector: 'sector-as-select',
    template: require('./sector-as-select.pug'),
    styles: [require('./sector-as-select.css')],
})

export class SectorAsSelectComponent implements OnInit {

    @Input() sector: Sector; 
    @Output() sectorChanges = new EventEmitter<Sector>();

    sectors: Sector[];
    selectedSector: Sector;
    
    errors: {field?: Error} = {};
    submitted = false;

    isAdmin;
    isLoggedIn;
    
    
    static parameters = [HttpClient, ActivatedRoute, AuthService];
    
    constructor(private http: HttpClient, private route: ActivatedRoute, private authService: AuthService, ) {
        this.http = http;
        this.route = route; 
        this.authService = authService;
        
        this.authService.isLoggedIn().then(is => {
            this.isLoggedIn = is;
        });

        this.authService.isAdmin().then(is => {
            this.isAdmin = is;
        });
    }

    ngOnInit() {
        this.http.get('/api/sectors/').subscribe( (sectorsLoaded: Sector[]) => {
            this.sectors = sectorsLoaded;
        });
    }

    onSectorChange(s) {
        console.log("SectorAsSelectComponent -- onSectorChange");
        this.selectedSector = s;
        this.sectorChanges.emit(this.selectedSector);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("SectorAsSelectComponent -- imposto il valore");
        this.selectedSector = changes.sector.currentValue
    }
    
    compareById(o1, o2) {
        return o1 && o2 && o1.id == o2.id;
    }


}
